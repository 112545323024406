import React, { createContext, useState, useEffect } from 'react';


export const ToggleContext = createContext();

export const ToggleProvider = ({ children }) => {
  const [toggleState, setToggleState] = useState(
    localStorage.getItem("organic") === "social"
  );

  useEffect(() => {
    localStorage.setItem("organic", toggleState ? "social" : "organic");
  }, [toggleState]);

  return (
    <ToggleContext.Provider value={{ toggleState, setToggleState }}>
      {children}
    </ToggleContext.Provider>
  );
};
