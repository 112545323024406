export const PACKAGENAME_INIT = 'PACKAGENAME_INIT';
export const PACKAGENAME_INIT_SUCCESS = 'PACKAGENAME_INIT_SUCCESS';
export const PACKAGENAME_INIT_ERROR = 'PACKAGENAME_INIT_ERROR';

export const MENULIST_INIT = 'MENULIST_INIT';
export const MENULIST_INIT_SUCCESS = 'MENULIST_INIT_SUCCESS';
export const MENULIST_INIT_ERROR = 'MENULIST_INIT_ERROR';

export const SET_GLOBAL_PACKAGE_NAME = 'SET_GLOBAL_PACKAGE_NAME';
export const SET_GLOBAL_REFRESH_RENDERING = 'SET_GLOBAL_REFRESH_RENDERING';
