/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { Layout } from "./Components/Common/Template/layout";
import AppPages from "../src/Pages/AppPages";
import { AuthPage } from "./Components/Common/Login/Auth";
import ErrorsPage from "./Components/Common/Login/ErrorsExamples/ErrorsPage";
import { getLocalStorage } from "./Utils/const";
import { AUTH_DATA } from "./Utils/AUTH_CONST";

export function Routes() {
  const isAuthorized = JSON.parse(getLocalStorage(AUTH_DATA.AUTH));

  return (
    <Switch>
      {!isAuthorized ? (
        <>
          <Route exact path="/*" component={() => <Redirect to="/" />} />
          <Route path="/" component={AuthPage} />
          <Route path="/error" component={ErrorsPage} />
          <Route
            exact
            path="/change-password"
            component={() => <Redirect to="/auth/login" />}
          />
        </>
      ) : (
        <>
          <Layout>
            <AppPages />
          </Layout>
        </>
      )}
    </Switch>
  );
}
