import CONST from "./Const";
export const UpdateTickets = (formData, id) => {
  return {
    type: CONST.ML_TICKET.UPDATE_DATA.INIT,
    formData,
    id,
  };
};

export const FetchTicket = (mutliSelectID) => {
  return {
    type: CONST.ML_TICKET.SINGLE_TICKET.INIT,
    mutliSelectID,
  };
};

export const TicketPageActions = {
  postInput(data) {
    return {
      type: CONST.ML_TICKET.DOWNLOAD_TICKET.INIT,
      id: "DOWNLOAD_TICKET",
      data,
    };
  },
  update(data) {
    return {
      type: CONST.ML_TICKET.UPDATE_DATA.INIT,
      id: "UPDATE_DATA",
      data,
    };
  },
  getTable(query) {
    return {
      type: CONST.ML_TICKET.GET_DETAILS_TABLE.INIT,
      id: "GET_DETAILS_TABLE",
      query,
    };
  },
  getStatus(query) {
    return {
      type: CONST.ML_TICKET.GET_ML_STATUS_LIST.INIT,
      id: "GET_ML_STATUS_LIST",
      query,
    };
  },
  getPriority(query) {
    return {
      type: CONST.ML_TICKET.GET_ML_PRIORITY_LIST.INIT,
      id: "GET_ML_PRIORITY_LIST",
      query,
    };
  },
  getAssignee(query) {
    return {
      type: CONST.ML_TICKET.GET_ML_ASSIGNEE_LIST.INIT,
      id: "GET_ML_ASSIGNEE_LIST",
      query,
    };
  },
};

export const MerchantLaunderingDashboard = {
  GetTotalCounts(query) {
    return {
      type: CONST.DASHBOARD.TOTAL_COUNTS.INIT,
      id: "TOTAL_COUNTS",
      query,
    };
  },
  GetTotalIncident(query) {
    return {
      type: CONST.DASHBOARD.TOTAL_INCIDENTS.INIT,
      id: "TOTAL_INCIDENTS",
      query,
    };
  },
  GetUniqueUpiCount(query) {
    return {
      type: CONST.DASHBOARD.UNIQUE_UPI_COUNT.INIT,
      id: "UNIQUE_UPI_COUNT",
      query,
    };
  },
  GetUniqueWebsiteCount(query) {
    return {
      type: CONST.DASHBOARD.UNIQUE_WEBSITE_COUNT.INIT,
      id: "UNIQUE_WEBSITE_COUNT",
      query,
    };
  },
  GetTotalBank(query) {
    return {
      type: CONST.DASHBOARD.TOP_BANK.INIT,
      id: "TOP_BANK",
      query,
    };
  },
  GetTotalWebsites(query) {
    return {
      type: CONST.DASHBOARD.TOP_WEBSITES.INIT,
      id: "TOP_WEBSITES",
      query,
    };
  },

  GetTopPaymentGateway(query) {
    return {
      type: CONST.DASHBOARD.TOP_PAYMENT_GATEWAY.INIT,
      id: "TOP_PAYMENT_GATEWAY",
      query,
    };
  },
  GetTopOrigin(query) {
    return {
      type: CONST.DASHBOARD.TOP_ORIGIN.INIT,
      id: "TOP_ORIGIN",
      query,
    };
  },
};

export const mmlExportreportAction = {
  getTable(query) {
    return {
      type: CONST.EXPORT_REPOT.ML_EXPORT_REPORT_TABLE.INIT,
      id: "ML_EXPORT_REPORT_TABLE",
      query,
    };
  },
  getTableRe(query) {
    return {
      type: CONST.EXPORT_REPOT.ML_EXPORT_REPORT_TABLE.REFETCH,
      id: "ML_EXPORT_REPORT_TABLE",
      query,
    };
  },
  postData(data) {
    return {
      type: CONST.EXPORT_REPOT.ML_GENERATE_REPORT.INIT,
      id: "ML_GENERATE_REPORT",
      data,
    };
  },
  additionalFilter(data) {
    return {
      type: CONST.EXPORT_REPOT.ADDITIONAL_FILTER.INIT,
      id: "ADDITIONAL_FILTER",
      data,
    };
  },
};

export const AffiliateMonitoringExportReportPageAction = {
  getTable(query) {
    return {
      type: CONST.AFFILIATE_MONITORING_EXPORT_REPORT.AM_EXPORT_REPORT_TABLE.INIT,
      id: "AM_EXPORT_REPORT_TABLE",
      query,
    };
  },
  getTableRe(query) {
    return {
      type: CONST.AFFILIATE_MONITORING_EXPORT_REPORT.AM_EXPORT_REPORT_TABLE.REFETCH,
      id: "AM_EXPORT_REPORT_TABLE",
      query,
    };
  },
  postData(data) {
    return {
      type: CONST.AFFILIATE_MONITORING_EXPORT_REPORT.AM_GENERATE_REPORT.INIT,
      id: "AM_GENERATE_REPORT",
      data,
    };
  },
  additionalFilter(data) {
    return {
      type: CONST.AFFILIATE_MONITORING_EXPORT_REPORT.ADDITIONAL_FILTER.INIT,
      id: "ADDITIONAL_FILTER",
      data,
    };
  },
};

export const MerchantLaunderingIncidenceReport = {
  GetIncidenceReport(query) {
    return {
      type: CONST.INCIDENCES_REPORT.INCIDENCES_REPORT.INIT,
      id: "INCIDENCES_REPORT",
      query,
    };
  },
  getStatus(query) {
    return {
      type: CONST.INCIDENCES_REPORT.GET_ML_STATUS_LIST.INIT,
      id: "GET_ML_STATUS_LIST",
      query,
    };
  },
  getPriority(query) {
    return {
      type: CONST.INCIDENCES_REPORT.GET_ML_PRIORITY_LIST.INIT,
      id: "GET_ML_PRIORITY_LIST",
      query,
    };
  },
  getAssignee(query) {
    return {
      type: CONST.INCIDENCES_REPORT.GET_ML_ASSIGNEE_LIST.INIT,
      id: "GET_ML_ASSIGNEE_LIST",
      query,
    };
  },
  closeTicketOne(query) {
    return {
      type: CONST.INCIDENCES_REPORT.GET_INCIDENCE_REPORT_1.INIT,
      id: "GET_INCIDENCE_REPORT_1",
      query,
    };
  },
};

export const MerchantLaunderingReportConfig = {
  GetReportConfig(query) {
    return {
      type: CONST.REPORT_CONFIG.GET_REPORT_CONFIG_TABLE.INIT,
      id: "GET_REPORT_CONFIG_TABLE",
      query,
    };
  },
  Del(query) {
    return {
      type: CONST.REPORT_CONFIG.DELETE_REPORT_CONFIG.INIT,
      id: "DELETE_REPORT_CONFIG",
      query,
    };
  },
  clearDel() {
    return {
      type: CONST.REPORT_CONFIG.DELETE_REPORT_CONFIG.CLEAR,
      id: "DELETE_REPORT_CONFIG",
    };
  },
  add(data) {
    return {
      type: CONST.REPORT_CONFIG.ADD_REPORT_CONFIG.INIT,
      id: "ADD_REPORT_CONFIG",
      data,
    };
  },
};

export const UpdateReportConfig = (formData, id) => {
  return {
    type: CONST.REPORT_CONFIG.UPDATE_REPORT_CONFIG.INIT,
    formData,
    id,
  };
};

export const FetchReportConfig = (mutliSelectID) => {
  return {
    type: CONST.REPORT_CONFIG.SINGLE_REPORT_CONFIG.INIT,
    mutliSelectID,
  };
};

export const DashboardDownloadAction = {
  incidentVolumes(query) {
    return {
      type: CONST.DASHBOARD_DOWNLOAD.INCIDENT_VOLUME.INIT,
      id: "INCIDENT_VOLUME",
      query,
    };
  },
  top10websites(query) {
    return {
      type: CONST.DASHBOARD_DOWNLOAD.TOP10_WEBSITES.INIT,
      id: "TOP10_WEBSITES",
      query,
    };
  },
  websiteHostingCountries(query) {
    return {
      type: CONST.DASHBOARD_DOWNLOAD.WEBSITE_HOSTING_COUNTRIES.INIT,
      id: "WEBSITE_HOSTING_COUNTRIES",
      query,
    };
  },
  top10banks(query) {
    return {
      type: CONST.DASHBOARD_DOWNLOAD.TOP10_BANKS.INIT,
      id: "TOP10_BANKS",
      query,
    };
  },
  paymentGateway(query) {
    return {
      type: CONST.DASHBOARD_DOWNLOAD.PAYMENT_GATEWAY.INIT,
      id: "PAYMENT_GATEWAY",
      query,
    };
  },
};

export const setValue = (value) => {
  return {
    type: "SET_VALUE",
    payload: value,
  };
};
