import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export function ScrollTop() {
  return (
    <div
      id="kt_scrolltop"
      className="scrolltop"
      style={{ backgroundColor: "#A21094" }}
    >
      <span className="svg-icon">
        {/* <SVG
          title="Scroll to top"
          src={toAbsoluteUrl(
            "https://react-bootstrap.netlify.app/img/logo.svg"
          )}
        ></SVG> */}
        <i className="fa-solid fa-arrow-up" style={{color:"white"}}></i>
      </span>{" "}
    </div>
  );
}
