const events = ['click', 'load', 'keydown'];

export const FILTER_CATEGORY = {
  CHANNEL: 'FILTER_CATEGORY_CHANNEL',
  CATEGORY: 'FILTER_CATEGORY_CATEGORY',
  PUBLISHERS: 'FILTER_CATEGORY_PUBLISHERS',
  COUNTRY: 'FILTER_CATEGORY_COUNTRY',
};

export const FILTER_CATEGORY_SELECTED_VALUE = {
  CHANNEL: 'FILTER_CATEGORY_VALUE_CHANNEL',
  CATEGORY: 'FILTER_CATEGORY_VALUE_CATEGORY',
  PUBLISHERS: 'FILTER_CATEGORY_VALUE_PUBLISHERS',
  COUNTRY: 'FILTER_CATEGORY_VALUE_COUNTRY',
  SELECTED: 'FILTER_CATEGORY_CURRENT_SELECTED_VALUE',
  ALL_VALUES: 'FILTER_SELECTED_ALL_VALUES',
};
export const FILTER_CATEGORY_SELECTED_VALUE_BS = {
  CHANNEL: 'FILTER_CATEGORY_VALUE_CHANNE_BSL',
  CATEGORY: 'FILTER_CATEGORY_VALUE_CATEGORY_BS',
  PUBLISHERS: 'FILTER_CATEGORY_VALUE_PUBLISHERS_BS',
  COUNTRY: 'FILTER_CATEGORY_VALUE_COUNTRY_BS',
  SELECTED: 'FILTER_CATEGORY_CURRENT_SELECTED_VALUE_BS',
  ALL_VALUES: 'FILTER_SELECTED_ALL_VALUES_BS',
  DOWNLOAD:"FILTER_SELECTED_ALL_VALUES_BS_DOWNLOAD"
};
export const CLICK_FILTER_CATEGORY_SELECTED_VALUE = {
  CHANNEL: 'CLICK_FILTER_CATEGORY_VALUE_CHANNEL',
  CATEGORY: 'CLICK_FILTER_CATEGORY_VALUE_CATEGORY',
  PUBLISHERS: 'CLICK_FILTER_CATEGORY_VALUE_PUBLISHERS',
  COUNTRY: 'CLICK_FILTER_CATEGORY_VALUE_COUNTRY',
  SELECTED: 'CLICK_FILTER_CATEGORY_CURRENT_SELECTED_VALUE',
  ALL_VALUES: 'CLICK_FILTER_SELECTED_ALL_VALUES',
};

export const POSTBACK_CATEGORY_SELECTED_VALUE = {
  CHANNEL: 'POSTBACK_FILTER_CATEGORY_VALUE_CHANNEL',
  CATEGORY: 'POSTBACK_FILTER_CATEGORY_VALUE_CATEGORY',
  PUBLISHERS: 'POSTBACK_FILTER_CATEGORY_VALUE_PUBLISHERS',
  COUNTRY: 'POSTBACK_FILTER_CATEGORY_VALUE_COUNTRY',
  SELECTED: 'POSTBACK_FILTER_CATEGORY_CURRENT_SELECTED_VALUE',
  ALL_VALUES: 'POSTBACK_FILTER_SELECTED_ALL_VALUES',
  DOWNLOAD: 'POSTBACK_FILTER_SELECTED_ALL_VALUES_DOWNLOAD',
};

export const CAMPAIGN_CATEGORY_SELECTED_VALUE = {
  CHANNEL: 'CAMPAIGN_FILTER_CATEGORY_VALUE_CHANNEL',
  CATEGORY: 'CAMPAIGN_FILTER_CATEGORY_VALUE_CATEGORY',
  PUBLISHERS: 'CAMPAIGN_FILTER_CATEGORY_VALUE_PUBLISHERS',
  COUNTRY: 'CAMPAIGN_FILTER_CATEGORY_VALUE_COUNTRY',
  SELECTED: 'CAMPAIGN_FILTER_CATEGORY_CURRENT_SELECTED_VALUE',
  ALL_VALUES: 'CAMPAIGN_FILTER_SELECTED_ALL_VALUES',
  DOWNLOAD: 'CAMPAIGN_FILTER_SELECTED_ALL_VALUES_DOWNLOAD',
};
export const EVENT_FILTER_CATEGORY_SELECTED_VALUE = {
  CHANNEL: 'EVENT_FILTER_CATEGORY_VALUE_CHANNEL',
  CATEGORY: 'EVENT_FILTER_CATEGORY_VALUE_CATEGORY',
  PUBLISHERS: 'EVENT_FILTER_CATEGORY_VALUE_PUBLISHERS',
  COUNTRY: 'EVENT_FILTER_CATEGORY_VALUE_COUNTRY',
  SELECTED: 'EVENT_FILTER_CATEGORY_CURRENT_SELECTED_VALUE',
  ALL_VALUES: 'EVENT_FILTER_SELECTED_ALL_VALUES',
};
export const EVENT_REPORT_CATEGORY_SELECTED_VALUE = {
  CHANNEL: 'EVENT_REPORT_CATEGORY_VALUE_CHANNEL',
  CATEGORY: 'EVENT_REPORT_CATEGORY_VALUE_CATEGORY',
  PUBLISHERS: 'EVENT_REPORT_CATEGORY_VALUE_PUBLISHERS',
  COUNTRY: 'EVENT_REPORT_CATEGORY_VALUE_COUNTRY',
  SELECTED: 'EVENT_REPORT_CATEGORY_CURRENT_SELECTED_VALUE',
  ALL_VALUES: 'EVENT_REPORT_SELECTED_ALL_VALUES',
};
export const EVENT_REPORT_FILTER_CATEGORY_SELECTED_VALUE = {
  CHANNEL: 'EVENT_REPORT_FILTER_CATEGORY_VALUE_CHANNEL',
  CATEGORY: 'EVENT_REPORT_FILTER_CATEGORY_VALUE_CATEGORY',
  PUBLISHERS: 'EVENT_REPORT_FILTER_CATEGORY_VALUE_PUBLISHERS',
  COUNTRY: 'EVENT_REPORT_FILTER_CATEGORY_VALUE_COUNTRY',
  SELECTED: 'EVENT_REPORT_FILTER_CATEGORY_CURRENT_SELECTED_VALUE',
  ALL_VALUES: 'EVENT_REPORT_FILTER_SELECTED_ALL_VALUES',
};

export const MTRACKIT_FILTER_SELECTED_VALUE = {
  SELECTED: 'MTRACKIT_SELECTED',
  ALL_VALUES: 'MTRACKIT_ALL_VALUES',
};

export const FILTER_AWAF_VALUES = {
  DOWNLOAD: 'FILTER_AWAF_VALUES_DOWNLOAD',

};
export const FILTER_AWCO_VALUES = {
  DOWNLOAD: 'FILTER_AWCO_VALUES_DOWNLOAD',

};

export const EVENT_CATEGORY_SELECTED_VALUE = {
  CHANNEL: 'EVENT_FILTER_CATEGORY_VALUE_CHANNEL',
  CATEGORY: 'EVENT_FILTER_CATEGORY_VALUE_CATEGORY',
  PUBLISHERS: 'EVENT_FILTER_CATEGORY_VALUE_PUBLISHERS',
  COUNTRY: 'EVENT_FILTER_CATEGORY_VALUE_COUNTRY',
  SELECTED: 'EVENT_FILTER_CATEGORY_CURRENT_SELECTED_VALUE',
  ALL_VALUES: 'EVENT_FILTER_SELECTED_ALL_VALUES',
  DOWNLOAD: 'EVENT_FILTER_SELECTED_ALL_VALUES_DOWNLOAD',
};

// menu_list
export const MENU_LIST_VALUE = {
  MENU: 'MENU_LIST_VALUE',
};

export const AUTH_DATA = {
  AUTH: 'auth_data',
};

export const SESSION = {
  TOKEN: 'token',
  EXPIRED: 'session_expired',
  EXPIRED_MESSAGE: 'Token expired/invalid',
  RESET_TOKEN: 'resetToken',
  AUTH_FAILED: 'Auth failed',
  LAST_INTERACTION_TIME: 'lastInteractionTime',
  IDLE_TIME: 15,
};

export const REGEX = {
  EMAIL: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  DOB: /^\d{2}[-/.]\d{2}[-/.]\d{4}$/,
  PHONE: /^[0][1-9]\d{9}$|^[1-9]\d{9}$/,
  PHONE_NUMBER_1: /^[0-9]+(-[0-9]+)+$/,
  PHONE_NUMBER: /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/,
  NUMERIC: /^[0-9]+$/,
  NUMERIC_CHARS: /^[0-9-_]+$/,
  PASSWORD: /^[a-zA-Z0-9@#^\-_$]*$/,
  PASSWORD_MIN_MAX_LENGTH: /(?=^.{7,14}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
  TEXT: /^[a-zA-Z_ ]*$/,
  ALPHA_UPPER_CASE: /^[A-Z]*$/,
  ALPHA_NUMERIC: /^[a-zA-Z0-9 /]*$/,
  ALPHA_NUMERIC_CHARS: /^[a-zA-Z0-9@#^\-_$/]*$/,
  ALPHA_NUMERIC_CHARS_SPACE: /^[a-zA-Z0-9@^\-_.,àâçéèêëîïôûùüÿñæœ +g]*$/,
  ALPHA_NUMERIC_SPECIAL_CHARS: /^[a-zA-Z0-9@^\-_:/'"{}|.,àâçéèêëîïôûùüÿñæœ +g]*$/,
  ALPHA_CHARS_SPACE: /^[a-zA-Z^-_$.,àâçéèêëîïôûùüÿñæœ +g]*$/,
  ZIP_CODE: /^([0-9]){6}$/,
  CAID_NUMBER: /^([0-9]){6,8}$/,
  BIN_NUMBER: /^([0-9]){4,6}$/,
  NUMERIC_SIX_DIGITS: /^([0-9]{6}|[0-9]{8})$/,
  NUMERIC_FOUR_DIGITS: /^([0-9]){4}$/,
  SPACE: / +/g,
  URL: /^(ftp|http|https):\/\/[^ "]+\.[a-zA-Z]{2,4}$/,
  URL_ONE: /^(ftp|http|https):\/\/[^ "]+$/,
};

export const TOKEN = {
  token: localStorage.getItem('token'),
};

export const addEvents = eventHandler => {
  events.forEach(eventName => {
    window.addEventListener(eventName, eventHandler);
  });
};

export const removeEvents = eventHandler => {
  events.forEach(eventName => {
    window.removeEventListener(eventName, eventHandler);
  });
};
