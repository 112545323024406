import { combineReducers } from "redux";
import CONST from "./Const";

function BrandMonitoringTicketReducer(state = {}, action) {
    return Reducer(CONST.BM_TICKET[action.id], state, action);
  }
  function BrandMonitoringIncidentReducer(state = {}, action) {
    return Reducer(CONST.INCIDENT_REPORT[action.id], state, action);
  }

  function BrandMonitoringReportConfigReducer(state = {}, action) {
    return Reducer(CONST.REPORT_CONFIG[action.id], state, action);
  }

function dashboardReducer(state = {}, action) {
  return Reducer(CONST.DASHBOARD[action.id], state, action);
}
function dashboardExportReducer(state = {}, action) {
  return Reducer(CONST.DASHBOARD_EXPORT[action.id], state, action);
}
function ExportReportReducer(state = {}, action) {
  return Reducer(CONST.BRAND_MONITORING_EXPORT_REPORT[action.id], state, action);
}
export default combineReducers({

    BrandMonitoringTicket: BrandMonitoringTicketReducer,
    BrandMonitoringIncident:BrandMonitoringIncidentReducer,
    BrandMonitoringReportConfig:BrandMonitoringReportConfigReducer,
    dashboard: dashboardReducer,
    dashboardExport: dashboardExportReducer,
    ExportReport: ExportReportReducer,

 });

function Reducer(key, state, action) {
    switch (action.type) {
      case "SET_VALUE":
        return {
          ...state,
          value: action.payload,
        };
      case key?.INIT: {
        const data = { loading: true, data: null, error: null };
        const newState = { ...state };
        newState[action.id] = data;
        return newState;
      }
      case key?.SUCCESS: {
        const data = { loading: false, data: action.data, error: null };
        const newState = { ...state };
        newState[action.id] = data;
        return newState;
      }
      case key?.ERROR: {
        const data = { loading: false, data: null, error: action.error };
        const newState = { ...state };
        newState[action.id] = data;
        return newState;
      }
      case key?.CLEAR: {
        const data = { loading: false, data: null, error: null };
        const newState = { ...state };
        newState[action.id] = data;
        return newState;
      }
      default:
        return state;
    }
}