/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, Switch, Redirect } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../Template/_helpers';
import { ContentRoute } from '../../../Template/layout';
import Login from './Login';
// import Registration from "./Registration";
// import ForgotPassword from "./ForgotPassword";
import '../../../../Common/Template/_assets/sass/pages/login/classic/login-1.scss';
import BrandNewLogin from './BrandNewLogin';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './styles.css';

export function AuthPage() {
  let date = new Date();
  let year = date.getFullYear();
  // const colors = ["#0088FE", "#00C49F", "#FFBB28"];
  // const delay = 1000;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1000 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 999, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const productPortalMapping = [
    'mF Sentinel+ provides cutting-edge solutions to detect brand infringement instances, phishing scams and transaction-related fraud to ensure holistic protection against ever-evolving digital threats. ',
    'mF Effcent provides robust protection against unscrupulous activities conducted by affiliates and influencers using a brand’s assets and reduces the risk of brand guidelines violation. ',
    'mF Tickr provides automated compliance regulation across creative and content to reduce errors and maximize efficiency with brand-compliant creative assets.',
    'mF Pace provides best-in-class brand reputation protection by identifying brand-unsafe content, reducing brand abuse and maximising visibility and trust.',
    'mF Valid8, provides optimum protection from the ever-evolving threats of ad fraud, from impressions to events, ensuring your ads are seen by only your intended audience in a geo-targeted and brand-safe environment.'
  ];

  // const [index, setIndex] = React.useState(0);
  // const timeoutRef = React.useRef(null);

  // function resetTimeout() {
  //   if (timeoutRef.current) {
  //     clearTimeout(timeoutRef.current);
  //   }
  // }

  // React.useEffect(() => {
  //   resetTimeout();
  //   timeoutRef.current = setTimeout(
  //     () =>
  //       setIndex((prevIndex) =>
  //         prevIndex === colors.length - 1 ? 0 : prevIndex + 1
  //       ),
  //     delay
  //   );

  //   return () => {
  //     resetTimeout();
  //   };
  // }, [index]);

  return (
    <>
      <div className='d-flex flex-column flex-root'>
        {/*begin::Login*/}
        <div
          className='login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white'
          id='kt_login'
        >
          {/*begin::Aside*/}
          <div
            className='login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 responsive-login'
            style={
              {
                // backgroundImage: `url(${toAbsoluteUrl(
                //   "https://infringementportalcontent.mfilterit.com/images/media/bg/bg-4.jpg"
                // )})`,
                // maxWidth:"950px",
              }
            }
          >
            {/*begin: Aside Container*/}
            <div
              className='d-flex flex-row-fluid flex-column justify-content-between'
              style={{ height: '103%' }}
            >
              {/* start:: Aside header */}
              {/* <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img
                  alt="Logo"
                  className="max-h-120px"
                  src={toAbsoluteUrl(
                    "https://infringementportalcontent.mfilterit.com/images/media/logos/logo.png"
                  )}
                />
              </Link> */}
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className='flex-column-fluid d-flex flex-column justify-content-center align-items-center'>
                <div className='w-50' style={{ fontFamily: 'poppins' }}>
                  <h3
                    className='mb-5 text-white'
                    style={{ fontWeight: '900', fontSize: '33px' }}
                  >
                    Welcome to mFilterIt!
                  </h3>
                  <Carousel
                    responsive={responsive}
                    removeArrowOnDeviceType={['tablet', 'mobile', 'web']}
                    partialVisible
                    infinite
                    autoPlay
                    // showDots
                    arrows={false}
                  >
                    {productPortalMapping?.map((v) => {
                      let eachWord = v.split(' ');
                      let firstWord = eachWord[0];
                      let secondWord = eachWord[1];

                      let remainingWords = eachWord.slice(2).join(' ');

                      return (
                        <p
                          className='font-weight-lighter text-white opacity-80'
                          style={{ fontSize: '17px' }}
                        >
                          <span style={{ color: 'yellow', fontWeight: 'bold' }}>
                            {firstWord + secondWord + ' '}
                          </span>
                          {remainingWords}
                          <br />
                          {/* Brand Relevancy, Continuous Monitoring. */}
                        </p>
                      );
                      // <img
                      //   className='d-block'
                      //   src={v}
                      //   // src={URL(v)}
                      //   alt='profile'
                      //   style={{
                      //     objectFit: 'contain',
                      //     borderRadius: '5px',
                      //     height: '100px',
                      //     width: '100px',
                      //   }}
                      // />
                    })}
                  </Carousel>

                  {/* <h5 className="font-size-h3 mb-5 text-white">
                    Incident Mangement
                  </h5> */}

                  {/* <p className="font-weight-lighter text-white opacity-80" style={{fontSize:"17px"}}>
                    <span style={{color:"yellow",fontWeight:"bold"}}>mFSentinel+</span> provides cutting-edge solutions to detect brand infrigement instances phishing scams and transaction-related fraud to ensure holistic protection against ever-evolving digital threats.
                    <br />
                    
                  </p> */}
                  <a
                    href='https://mfilterit.com/brand-protection-solutions'
                    target='_blank'
                  >
                    <button
                      style={{
                        background: 'indigo',
                        borderRadius: '7px',
                        color: 'white',
                        fontWeight: 'bold',
                        border: '1px solid white',
                        paddingRight: '30px',
                        fontSize: '14px'
                      }}
                    >
                      Our Product Suite &#8594;
                    </button>
                  </a>
                </div>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className='d-none flex-column-auto d-lg-flex justify-content-around mt-10 align-items-center'>
                {/* <div className="d-flex"> */}
                {/* <Link to='' className='text-white'>
                    Privacy
                  </Link>
                  <Link to='' className='text-white ml-10'>
                    Legal
                  </Link> */}
                {/* <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.mfilterit.com/contact-us"
                    className="text-white ml-10"
                    style={{textDecoration:"underline"}}
                  >
                    Contact
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.mfilterit.com/terms-and-conditions"
                    className="text-white ml-10"
                    style={{textDecoration:"underline"}}
                  >
                    Legal
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.mfilterit.com/privacy-policy"
                    className="text-white ml-10"
                    style={{textDecoration:"underline"}}
                  >
                    Privacy
                  </a> */}
                {/* </div> */}
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className='d-flex flex-column flex-row-fluid position-relative overflow-hidden align-items-center'>
            <div className='d-flex flex-column-fluid flex-center mt-lg-0 mt-30'>
              <Switch>
                <ContentRoute path='/' component={BrandNewLogin} />
                {/* <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                /> */}
                {/* <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                /> */}
                <Redirect from='/auth' exact={true} to='/' />
                <Redirect to='/' />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
