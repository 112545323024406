import { combineReducers } from "redux";
import CONST from "./Const";

function MerchantLaunderingTicketReducer(state = {}, action) {
  return Reducer(CONST.ML_TICKET[action.id], state, action);
}

function InvestmentScamTicketReducer(state = {}, action) {
  return Reducer(CONST.ML_TICKET[action.id], state, action);
}


function MerchantLaunderingDashboardReducer(state = {}, action) {
  return Reducer(CONST.DASHBOARD[action.id], state, action);
}

function MerchantLaunderingExportReportReducer(state = {}, action) {
  return Reducer(CONST.EXPORT_REPOT[action.id], state, action);
}
function AffiliateMonitoringExportReportPageReducer(state = {}, action) {
  return Reducer(CONST.AFFILIATE_MONITORING_EXPORT_REPORT[action.id], state, action);
}
function MerchantLaunderingIncidenceReportReducer(state = {}, action) {
  return Reducer(CONST.INCIDENCES_REPORT[action.id], state, action);
}
function MerchantLaunderingReportConfigReducer(state = {}, action) {
  return Reducer(CONST.REPORT_CONFIG[action.id], state, action);
}
function MerchantLaunderingReportDownloadReducer(state = {}, action) {
  return Reducer(CONST.DASHBOARD_DOWNLOAD[action.id], state, action);
}
export default combineReducers({
  MerchantLaunderingTicket: MerchantLaunderingTicketReducer,
  InvestmentScamTicket:InvestmentScamTicketReducer,
  MerchantLaunderingDashboard: MerchantLaunderingDashboardReducer,
  MlExportReport: MerchantLaunderingExportReportReducer,
  AMExportReport: AffiliateMonitoringExportReportPageReducer,
  MerchantLaunderingIncidence: MerchantLaunderingIncidenceReportReducer,
  MerchantLaunderingReportConfig: MerchantLaunderingReportConfigReducer,
  MerchantLaunderingDownload: MerchantLaunderingReportDownloadReducer,
});

function Reducer(key, state, action) {
  switch (action.type) {
    case "SET_VALUE":
      return {
        ...state,
        value: action.payload,
      };
    case key?.INIT: {
      const data = { loading: true, data: null, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.SUCCESS: {
      const data = { loading: false, data: action.data, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.ERROR: {
      const data = { loading: false, data: null, error: action.error };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.CLEAR: {
      const data = { loading: false, data: null, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    default:
      return state;
  }
}
