import { takeLatest, put } from 'redux-saga/effects';
import CONST from './Const';
import { API, APIDROPDOWN } from '../setupAxios';
import toast, { Toaster } from 'react-hot-toast';
import { ApiUrl } from '../helpers/Endpoints';
import { DNSLIST } from '../services/dns';

const IdentityUrl = DNSLIST.DASHBOARD_360.HOME;
const IdentityScanUrl = DNSLIST.IDSCAN.DEFAULT;
const InfringementUrl = 'https://infringementapi.mfilterit.net';

const signout = () => {
  localStorage.removeItem('auth_data');
  localStorage.removeItem('dpackage');
  localStorage.removeItem('startDateValue');
  localStorage.removeItem('startDate');
  localStorage.removeItem('endDate');
  window.location = '/';
};
const Endpoint = {
  IDENTITY_SCAN: {
    POST_DETAILS: {
      domain: IdentityScanUrl,
      end: '',
      method: 'POSTCUSTOMHEAD'
    },
    DELETE: {
      domain: ApiUrl,
      end: '/user-management/delete-user',
      method: 'DELETE_QUERY'
    },
    GET_USER_TABLE: '/user-management',
    DROPDOWN_API: {
      domain: IdentityScanUrl,
      end: '/nodes',
      method: 'POSTCUSTOMHEAD'
    },
    GET_DETAILS_TABLE: {
      domain: IdentityScanUrl,
      end: '/jobs',
      method: 'POSTCUSTOMHEAD'
    },
    SIGNUP: {
      domain: IdentityUrl,
      end: '/user-management/signup',
      method: 'POST'
    },
    UPDATE_DETAILS: {
      domain: IdentityUrl,
      end: '/user-management/update-user/',
      method: 'UPDATE_QUERY'
    }
  },
  Filters: {
    FILTER_LIST: '/user-management/filters-list',
    CHANNELS: '/dashboard/filters/channel',
    CATEGORIES: '/dashboard/filters/category',
    PUBLISHER: '/dashboard/filters/publisher',
    COUNTRIES: '/dashboard/filters/country',
    BRANDS: '/dashboard/filters/brand',
    PRIORITIES: '/dashboard/filters/priority',
    STATUS: '/dashboard/filters/status',
    SUB_CHANNEL: '/dashboard/filters/subchannel',
    MERCHANT_LAUNDERING_PUBLISHER_FILTER:
      '/merchant-laundering/filter/publisher',
    MERCHANT_LAUNDERING_SUB_CHANNEL_FILTER:
      '/merchant-laundering/filter/sub_channel_name',
    MERCHANT_LAUNDERING_CATEGORY_FILTER: '/merchant-laundering/filter/category',
    MERCHANT_LAUNDERING_SUB_CATEGORY_FILTER:
      '/merchant-laundering/filter/sub_category',
    MERCHANT_LAUNDERING_COUNTRY_FILTER: '/merchant-laundering/filter/country',
    MERCHANT_LAUNDERING_SUB_SUB_CATEGORY_FILTER:
      '/merchant-laundering/filter/sub_sub_category',
    MERCHANT_LAUNDERING_UPI_FILTER: '/merchant-laundering/filter/sm_handler',

    INVESTMENT_SCAMS_PUBLISHER_FILTER:
    '/investment-scams/filter/publisher',
    INVESTMENT_SCAMS_SUB_CHANNEL_FILTER:
    '/investment-scams/filter/sub_channel_name',
    INVESTMENT_SCAMS_CATEGORY_FILTER: '/investment-scams/filter/category',
    INVESTMENT_SCAMS_SUB_CATEGORY_FILTER:
    '/investment-scams/filter/sub_category',
    INVESTMENT_SCAMS_COUNTRY_FILTER: '/investment-scams/filter/country',
    INVESTMENT_SCAMS_SUB_SUB_CATEGORY_FILTER:
    '/investment-scams/filter/sub_sub_category',
    INVESTMENT_SCAMS_UPI_FILTER: '/investment-scams/filter/sm_handler',

    AFFILIATE_MONITORING_PUBLISHER_FILTER:
      '/affiliate-monitoring/filter/publisher',
    AFFILIATE_MONITORING_CHANNEL_FILTER: '/affiliate-monitoring/filter/channel',
    AFFILIATE_MONITORING_SUB_CHANNEL_FILTER:
      '/affiliate-monitoring/filter/sub_channel',
    AFFILIATE_MONITORING_CATEGORY_FILTER:
      '/affiliate-monitoring/filter/category',
    AFFILIATE_MONITORING_SUB_CATEGORY_FILTER:
      '/affiliate-monitoring/filter/sub_category',
    AFFILIATE_MONITORING_COUNTRY_FILTER: '/affiliate-monitoring/filter/country',
    AFFILIATE_MONITORING_KEYWORD_FILTER: '/affiliate-monitoring/filter/keyword',
    AFFILIATE_MONITORING_DOMAIN_FILTER: '/affiliate-monitoring/filter/domain',
    AFFILIATE_MONITORING_PRIORITY_FILTER:
      '/affiliate-monitoring/filter/priority',
    AFFILIATE_MONITORING_STATUS_FILTER: '/affiliate-monitoring/filter/status',
    AFFILIATE_MONITORING_BRAND_FILTER: '/affiliate-monitoring/filter/brand',
    AFFILIATE_MONITORING_LOCATION_FILTER:
      '/affiliate-monitoring/filter/location',
    // =====================================
    BRAND_MONITORING_PUBLISHER_FILTER:
      '/brand-monitoring/filter/contact_number',
    BRAND_MONITORING_CHANNEL_FILTER: '/brand-monitoring/filter/channel',
    BRAND_MONITORING_SUB_CHANNEL_FILTER: '/brand-monitoring/filter/sub_channel',
    BRAND_MONITORING_CATEGORY_FILTER: '/brand-monitoring/filter/category',
    BRAND_MONITORING_COUNTRY_FILTER: '/brand-monitoring/filter/country',
    BRAND_MONITORING_UPLOAD_DATE_FILTER: '/brand-monitoring/filter/upload_date',
    BRAND_MONITORING_PRIORITY_FILTER: '/brand-monitoring/filter/priority',
    BRAND_MONITORING_STATUS_FILTER: '/brand-monitoring/filter/status',
    BRAND_MONITORING_BRAND_FILTER: '/brand-monitoring/filter/brand',
    BRAND_MONITORING_TAKEDOWN_STATUS_FILTER:
      '/brand-monitoring/filter/takedown_status'
  },
  SEARCH_360_DEGREE: {
    GET_DETAILS_TABLE: '/dashboard/360-details',
    POST_DETAILS: {
      domain: ApiUrl,
      end: '/dashboard/360-upload',
      method: 'POSTFORMDATA'
    }
  },
  PACKAGE_MAPPING: {
    GET_DETAILS_TABLE: '/user-management/package-mapping',
    GET_USER_LIST: '/user-management/users-list',
    GET_PACKAGE_LIST: '/package-details/package/all',
    GET_FILTER_LIST: '/package-details/filter',
    GETT_PACKAGE_MENU_LIST: '/package-details/menu',
    GET_PACKAGE_INCIDENT_LIST: '/package-details/report-columns',
    POST_DETAILS: {
      domain: ApiUrl,
      end: '/user-management/UPM',
      method: 'POST'
    },
    POST_DETAILS_PACKAGE: {
      domain: ApiUrl,
      end: '/package-details/package',
      method: 'POST'
    },
    DELETE: {
      domain: ApiUrl,
      end: '/user-management/UPM',
      method: 'DELETE_QUERY'
    },
    GET_PACKAGE_DATA: {
      domain: ApiUrl,
      end: '/user-management/UPM',
      method: 'GET_DYNAMIC_ENDPOINT'
    },
    UPDATE_DETAILS: {
      domain: ApiUrl,
      end: '/user-management/UPM/',
      method: 'UPDATE_QUERY'
    }
  },
  CUSTOMER_REPORT_CONFIG: {
    GET_DETAILS_TABLE: '/package-details/customer-config',
    DELETE: {
      domain: ApiUrl,
      end: '/package-details/customer-config',
      method: 'DELETE_QUERY'
    },
    // DELETE: '/package-details/customer-config',
    // GET_USER_LIST: '/user-management/users-list',
    // GET_PACKAGE_LIST: '/package-details/package/all',
    // GET_FILTER_LIST: '/package-details/filter',
    // GETT_PACKAGE_MENU_LIST: '/package-details/menu',
    // GET_PACKAGE_INCIDENT_LIST: '/package-details/report-columns',
    POST_DETAILS: {
      domain: ApiUrl,
      end: '/package-details/customer-config',
      method: 'POST'
    }
    // POST_DETAILS_PACKAGE: {
    //   domain: ApiUrl,
    //   end: '/package-details/package',
    //   method: 'POST'
    // },
    // DELETE: {
    //   domain: ApiUrl,
    //   end: '/user-management/UPM',
    //   method: 'DELETE_QUERY'
    // },
    // GET_PACKAGE_DATA: {
    //   domain: ApiUrl,
    //   end: '/user-management/UPM',
    //   method: 'GET_DYNAMIC_ENDPOINT'
    // },
    // UPDATE_DETAILS: {
    //   domain: ApiUrl,
    //   end: '/user-management/UPM/',
    //   method: 'UPDATE_QUERY'
    // }
  },
  REDMINE_MAPPING: {
    GET_DETAILS_TABLE: '/user-management/URM',
    GET_PROJECT_LIST: '/dashboard/ticket-project',
    GET_MEMBERSHIP_LIST: {
      domain: ApiUrl,
      end: '/redmine/project-membership',
      method: 'GET_DYNAMIC_ENDPOINT'
    },
    GET_REDMINE_DATA: {
      domain: ApiUrl,
      end: '/user-management/URM',
      method: 'GET_DYNAMIC_ENDPOINT'
    },
    POST_DETAILS: {
      domain: ApiUrl,
      end: '/user-management/URM',
      method: 'POST'
    },
    UPDATE_DETAILS: {
      domain: ApiUrl,
      end: '/user-management/URM/',
      method: 'UPDATE_QUERY'
    },
    DELETE: {
      domain: ApiUrl,
      end: '/user-management/URM',
      method: 'DELETE_QUERY'
    }
  },
  DASHBOARD: {
    COUNT: '/count',
    INCIDENT_VOLUME: '/incident-volume',
    CASE_BY_COUNTRY: '/case-by-country',
    CASE_BY_CHANNEL: '/case-by-channel',
    CASE_BY_SUB_CHANNEL: '/case-by-sub-channel',
    CASE_BY_CATEGORY: '/case-by-category',
    CASE_BY_SUB_CATEGORY: '/case-by-sub-category',
    CASE_BY_PUBLISHER: '/publisher-volume',
    TOP_LOCATION: '/top-location',
    TOP_KEYWORD: '/top-keyword',
    TOP_DOMAIN: '/top-domain'
  },
  INCIDENT: {
    GET_INCIDENT: '/incidences',
    GET_INCIDENT_V2: '/incidences-v2'
  },
  EXPORT_DASHBOARD: {
    COUNT_EXPORT: '/count',
    INCIDENT_VOLUME_EXPORT: '/incident-volume',
    CASE_BY_COUNTRY_EXPORT: '/case-by-country',
    CASE_BY_CHANNEL_EXPORT: '/case-by-channel',
    CASE_BY_SUB_CHANNEL_EXPORT: '/case-by-sub-channel',
    CASE_BY_CATEGORY_EXPORT: '/case-by-category',
    CASE_BY_SUB_CATEGORY_EXPORT: '/case-by-sub-category',
    CASE_BY_PUBLISHER_EXPORT: '/publisher-volume',
    TOP_LOCATION_EXPORT: '/top-location',
    TOP_KEYWORD_EXPORT: '/top-keyword',
    TOP_DOMAIN_EXPORT: '/top-domain'
  },
  BI_TICKET: {
    GET_DETAILS_TABLE: '/redmine/all/tickets',
    SINGLE_TICKET: '/redmine/single/',
    // UPDATE_DATA: {
    //   domain: ApiUrl,
    //   end: "/update-ticket",
    //   method: "POST",
    // },
    UPDATE_DATA: '/redmine/update-ticket',
    GET_PRIORITY_LIST: '/dashboard/ticket-priority',
    GET_STATUS_LIST: '/dashboard/ticket-status',
    GET_ASSIGNEE_LIST: '/redmine/project-membership/1'
  },
  EXPORT_REPORT: {
    GET_DETAILS_TABLE: '/affiliate-monitoring/report',
    GENERATE_REPORT: {
      domain: ApiUrl,
      end: '/affiliate-monitoring/report/generate?type=xlsx',
      method: 'POST'
    }
  },
  DIGITAL_FOOTPRINT: {
    GET_DETAILS_TABLE: '/digitalfootprint/lazy',
    DOWNLOAD_REPORT: '/digitalfootprint/download'
  }
};

function sagaGenerator(endpoint, constant) {
  if (!endpoint) throw Error(`Endpoint not defined`);
  if (!constant) throw Error(`Constant not defined`);
  return function*(payload) {
    try {
      const params = payload.query;
      if (typeof endpoint === 'object') {
        if (!endpoint.end && !endpoint.domain)
          throw Error('Missing parameters in Endpoint.');
        const url = endpoint.domain + endpoint.end;
        switch (endpoint.method) {
          case 'GET': {
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'DELETE_QUERY': {
            const response = yield API.delete(url + '/' + payload.data, {});
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'UPDATE_QUERY': {
            let posturl = url + payload.payload.id;
            const response = yield API.put(posturl, payload.payload.data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'POST': {
            const data = payload.data;
            const response = yield API.post(url, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'POSTFORMDATA': {
            const data = payload.data;
            const formData = new FormData();
            const csv = data.csv?.files[0];
            if (!csv) throw new Error('File not selected');
            formData.append('csv', csv, 'data.csv');
            const extra = data.extra?.files[0];
            // return {};
            if (extra) {
              formData.append('extra', extra);
            }
            const response = yield API.post(url, formData);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'POSTCUSTOMHEAD': {
            const data = payload.data;

            const response = yield APIDROPDOWN.post(url);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'GET_DYNAMIC_ENDPOINT': {
            const response = yield API.get(url + '/' + params, {});
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'POST_DYNAMIC_ENDPOINT': {
            const response = yield API.post(url + '/' + params, {});
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'POST_QUERY': {
            const params = payload.query;
            const response = yield API.post(url, {}, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'PUT': {
            const data = payload.data;
            const response = yield API.put(url, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'DELETE': {
            const data = payload.data;
            const response = yield API.delete(url, { params, data });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          default: {
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
          }
        }
      } else {
        const url = ApiUrl + endpoint;
        const response = yield API.get(url, { params });
        yield put({
          type: constant.SUCCESS,
          id: payload.id,
          data: response
        });
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout();
      console.error(payload.id, error);
      yield put({
        type: constant.ERROR,
        id: payload.id,
        error
      });
    }
  };
}
export function* getIdentityDetailData(payload) {
  try {
    if (!Endpoint.IDENTITY_SCAN[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = IdentityUrl + Endpoint.IDENTITY_SCAN[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.IDENTITY_SCAN[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.IDENTITY_SCAN[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* get360DegreeSearchData(payload) {
  try {
    if (!Endpoint.SEARCH_360_DEGREE[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.SEARCH_360_DEGREE[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.SEARCH_360_DEGREE[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.SEARCH_360_DEGREE[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* getPackageMappinghData(payload) {
  try {
    if (!Endpoint.PACKAGE_MAPPING[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.PACKAGE_MAPPING[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.PACKAGE_MAPPING[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.PACKAGE_MAPPING[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}

export function* getCustomerReportConfigData(payload) {
  try {
    if (!Endpoint.CUSTOMER_REPORT_CONFIG[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.CUSTOMER_REPORT_CONFIG[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.CUSTOMER_REPORT_CONFIG[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.CUSTOMER_REPORT_CONFIG[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* getRedmineMappingData(payload) {
  try {
    if (!Endpoint.REDMINE_MAPPING[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.REDMINE_MAPPING[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.REDMINE_MAPPING[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.REDMINE_MAPPING[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* getBiTicketData(payload) {
  try {
    if (!Endpoint.BI_TICKET[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.BI_TICKET[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.BI_TICKET[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.BI_TICKET[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* getBiDigitalFootprintData(payload) {
  try {
    if (!Endpoint.DIGITAL_FOOTPRINT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.DIGITAL_FOOTPRINT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.DIGITAL_FOOTPRINT[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.DIGITAL_FOOTPRINT[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}

export function* getExportReportData(payload) {
  try {
    if (!Endpoint.EXPORT_REPORT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.EXPORT_REPORT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.EXPORT_REPORT[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.EXPORT_REPORT[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}

export function* TicketUpdateSaga(payload) {
  try {
    let url = ApiUrl + Endpoint.BI_TICKET.UPDATE_DATA + '/' + payload.id;
    const response = yield API.put(url, payload.formData);
    if (response.status === true) {
      toast.success('Ticket Update Successfully');
    } else {
      toast.error('Something Went Wrong');
    }
    yield put({ type: CONST.BI_TICKET.UPDATE_DATA.SUCCESS, response });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    yield put({ type: CONST.BI_TICKET.UPDATE_DATA.ERROR, error });
  }
}

export function* GetTicketdetails(payload) {
  try {
    let queryParams = { ...payload.mutliSelectID };
    let searchParams = new URLSearchParams();
    Object.keys(queryParams).forEach((key) =>
      searchParams.append(key, queryParams[key])
    );
    let url = ApiUrl + Endpoint.BI_TICKET.SINGLE_TICKET + payload.mutliSelectID;

    const response = yield API.get(url);
    yield put({ type: CONST.BI_TICKET.SINGLE_TICKET.SUCCESS, response });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    yield put({ type: CONST.BI_TICKET.SINGLE_TICKET.ERROR, error });
  }
}

export function* getIdentityDropdownData(payload) {
  try {
    if (!Endpoint.IDENTITY_SCAN[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = IdentityScanUrl + Endpoint.IDENTITY_SCAN[payload.id];
    const response = yield APIDROPDOWN.get(url, { params });
    yield put({
      type: CONST.IDENTITY_SCAN[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.IDENTITY_SCAN[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}

export function* getDashboardData(payload) {
  try {
    if (!Endpoint.DASHBOARD[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url =
      ApiUrl +
      '/affiliate-monitoring/dashboard' +
      Endpoint.DASHBOARD[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.DASHBOARD[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.DASHBOARD[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* getIncidentData(payload) {
  try {
    if (!Endpoint.INCIDENT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url =
      ApiUrl + '/affiliate-monitoring' + Endpoint.INCIDENT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.INCIDENT[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.INCIDENT[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* getSingleTicket(payload) {
  try {
    let queryParams = { ...payload.mutliSelectID };
    let searchParams = new URLSearchParams();
    Object.keys(queryParams).forEach((key) =>
      searchParams.append(key, queryParams[key])
    );
    let url = ApiUrl + Endpoint.BI_TICKET.SINGLE_TICKET + payload.mutliSelectID;

    const response = yield API.get(url);
    yield put({ type: CONST.BI_TICKET.SINGLE_TICKET.SUCCESS, response });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    yield put({ type: CONST.BI_TICKET.SINGLE_TICKET.ERROR, error });
  }
}
export function* getExportDashboardData(payload) {
  try {
    if (!Endpoint.EXPORT_DASHBOARD[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url =
      ApiUrl +
      '/affiliate-monitoring/dashboard' +
      Endpoint.EXPORT_DASHBOARD[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.EXPORT_DASHBOARD[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.EXPORT_DASHBOARD[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}

export function* getFilterData(payload) {
  try {
    if (!Endpoint.Filters[payload.id]) throw Error('Endpoint not defined');
    const params = payload.query;
    const url = ApiUrl + Endpoint.Filters[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.FILTER[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    yield put({
      type: CONST.FILTER[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export default function* IdentityScanSaga() {
  yield takeLatest(
    CONST.IDENTITY_SCAN.POST_DETAILS.INIT,
    sagaGenerator(
      Endpoint.IDENTITY_SCAN.POST_DETAILS,
      CONST.IDENTITY_SCAN.POST_DETAILS
    )
  );
  yield takeLatest(
    CONST.SIGNUP.USERDATA.INIT,
    sagaGenerator(Endpoint.IDENTITY_SCAN.SIGNUP, CONST.SIGNUP.USERDATA)
  );
  yield takeLatest(
    CONST.IDENTITY_SCAN.GET_USER_TABLE.INIT,
    getIdentityDetailData
  );
  // yield takeLatest(
  //   CONST.IDENTITY_SCAN.DROPDOWN_API.INIT,
  //   getIdentityDropdownData
  // );
  yield takeLatest(
    CONST.IDENTITY_SCAN.DROPDOWN_API.INIT,
    sagaGenerator(
      Endpoint.IDENTITY_SCAN.DROPDOWN_API,
      CONST.IDENTITY_SCAN.DROPDOWN_API
    )
  );
  yield takeLatest(
    CONST.IDENTITY_SCAN.DELETE.INIT,
    sagaGenerator(Endpoint.IDENTITY_SCAN.DELETE, CONST.IDENTITY_SCAN.DELETE)
  );
  // yield takeLatest(
  //   CONST.CUSTOMER_REPORT_CONFIG.DELETE.INIT,
  //   sagaGenerator(Endpoint.CUSTOMER_REPORT_CONFIG.DELETE.INIT, CONST.CUSTOMER_REPORT_CONFIG.DELETE)
  // );
  yield takeLatest(
    CONST.PACKAGE_MAPPING.DELETE.INIT,
    sagaGenerator(Endpoint.PACKAGE_MAPPING.DELETE, CONST.PACKAGE_MAPPING.DELETE)
  );
  yield takeLatest(
    CONST.IDENTITY_SCAN.UPDATE_DETAILS.INIT,
    sagaGenerator(
      Endpoint.IDENTITY_SCAN.UPDATE_DETAILS,
      CONST.IDENTITY_SCAN.UPDATE_DETAILS
    )
  );
  yield takeLatest(
    CONST.IDENTITY_SCAN.GET_DETAILS_TABLE.INIT,
    sagaGenerator(
      Endpoint.IDENTITY_SCAN.GET_DETAILS_TABLE,
      CONST.IDENTITY_SCAN.GET_DETAILS_TABLE
    )
  );
  yield takeLatest(
    CONST.SEARCH_360_DEGREE.POST_DETAILS.INIT,
    sagaGenerator(
      Endpoint.SEARCH_360_DEGREE.POST_DETAILS,
      CONST.SEARCH_360_DEGREE.POST_DETAILS
    )
  );
  yield takeLatest(
    CONST.EXPORT_REPORT.GENERATE_REPORT.INIT,
    sagaGenerator(
      Endpoint.EXPORT_REPORT.GENERATE_REPORT,
      CONST.EXPORT_REPORT.GENERATE_REPORT
    )
  );
  yield takeLatest(
    CONST.PACKAGE_MAPPING.POST_DETAILS.INIT,
    sagaGenerator(
      Endpoint.PACKAGE_MAPPING.POST_DETAILS,
      CONST.PACKAGE_MAPPING.POST_DETAILS
    )
  );
  yield takeLatest(
    CONST.PACKAGE_MAPPING.POST_DETAILS_PACKAGE.INIT,
    sagaGenerator(
      Endpoint.PACKAGE_MAPPING.POST_DETAILS_PACKAGE,
      CONST.PACKAGE_MAPPING.POST_DETAILS_PACKAGE
    )
  );
  // yield takeLatest(CONST.FILTER.FILTER_LIST.INIT, getFilterData);

  for (let key in CONST.DASHBOARD) {
    if (CONST.DASHBOARD.hasOwnProperty(key)) {
      yield takeLatest(CONST.DASHBOARD[key].INIT, getDashboardData);
    }
  }
  for (let key in CONST.EXPORT_DASHBOARD) {
    if (CONST.EXPORT_DASHBOARD.hasOwnProperty(key)) {
      yield takeLatest(
        CONST.EXPORT_DASHBOARD[key].INIT,
        getExportDashboardData
      );
    }
  }
  yield takeLatest(CONST.INCIDENT.GET_INCIDENT.INIT, getIncidentData);
  yield takeLatest(CONST.INCIDENT.GET_INCIDENT_V2.INIT, getIncidentData);
  for (let key in CONST.FILTER) {
    if (CONST.FILTER.hasOwnProperty(key)) {
      yield takeLatest(CONST.FILTER[key].INIT, getFilterData);
    }
  }
  yield takeLatest(
    CONST.DIGITAL_FOOTPRINT.GET_DETAILS_TABLE.INIT,
    getBiDigitalFootprintData
  );
  yield takeLatest(
    CONST.DIGITAL_FOOTPRINT.DOWNLOAD_REPORT.INIT,
    getBiDigitalFootprintData
  );
  yield takeLatest(
    CONST.SEARCH_360_DEGREE.GET_DETAILS_TABLE.INIT,
    get360DegreeSearchData
  );
  yield takeLatest(
    CONST.CUSTOMER_REPORT_CONFIG.GET_DETAILS_TABLE.INIT,
    getCustomerReportConfigData
  );
  yield takeLatest(
    CONST.CUSTOMER_REPORT_CONFIG.DELETE.INIT,
    getCustomerReportConfigData
  );
  yield takeLatest(
    CONST.PACKAGE_MAPPING.GET_DETAILS_TABLE.INIT,
    getPackageMappinghData
  );
  yield takeLatest(
    CONST.PACKAGE_MAPPING.GET_PACKAGE_DATA.INIT,
    sagaGenerator(
      Endpoint.PACKAGE_MAPPING.GET_PACKAGE_DATA,
      CONST.PACKAGE_MAPPING.GET_PACKAGE_DATA
    )
  );
  yield takeLatest(
    CONST.PACKAGE_MAPPING.UPDATE_DETAILS.INIT,
    sagaGenerator(
      Endpoint.PACKAGE_MAPPING.UPDATE_DETAILS,
      CONST.PACKAGE_MAPPING.UPDATE_DETAILS
    )
  );
  yield takeLatest(
    CONST.REDMINE_MAPPING.GET_DETAILS_TABLE.INIT,
    getRedmineMappingData
  );
  yield takeLatest(
    CONST.REDMINE_MAPPING.GET_PROJECT_LIST.INIT,
    getRedmineMappingData
  );
  yield takeLatest(
    CONST.REDMINE_MAPPING.GET_MEMBERSHIP_LIST.INIT,
    sagaGenerator(
      Endpoint.REDMINE_MAPPING.GET_MEMBERSHIP_LIST,
      CONST.REDMINE_MAPPING.GET_MEMBERSHIP_LIST
    )
  );
  yield takeLatest(
    CONST.REDMINE_MAPPING.GET_REDMINE_DATA.INIT,
    sagaGenerator(
      Endpoint.REDMINE_MAPPING.GET_REDMINE_DATA,
      CONST.REDMINE_MAPPING.GET_REDMINE_DATA
    )
  );
  yield takeLatest(
    CONST.REDMINE_MAPPING.POST_DETAILS.INIT,
    sagaGenerator(
      Endpoint.REDMINE_MAPPING.POST_DETAILS,
      CONST.REDMINE_MAPPING.POST_DETAILS
    )
  );
  yield takeLatest(
    CONST.REDMINE_MAPPING.DELETE.INIT,
    sagaGenerator(Endpoint.REDMINE_MAPPING.DELETE, CONST.REDMINE_MAPPING.DELETE)
  );
  yield takeLatest(
    CONST.REDMINE_MAPPING.UPDATE_DETAILS.INIT,
    sagaGenerator(
      Endpoint.REDMINE_MAPPING.UPDATE_DETAILS,
      CONST.REDMINE_MAPPING.UPDATE_DETAILS
    )
  );
  yield takeLatest(
    CONST.PACKAGE_MAPPING.GET_PACKAGE_INCIDENT_LIST.INIT,
    getPackageMappinghData
  );
  yield takeLatest(
    CONST.PACKAGE_MAPPING.GET_USER_LIST.INIT,
    getPackageMappinghData
  );
  yield takeLatest(
    CONST.PACKAGE_MAPPING.GET_PACKAGE_LIST.INIT,
    getPackageMappinghData
  );
  yield takeLatest(
    CONST.PACKAGE_MAPPING.GET_FILTER_LIST.INIT,
    getPackageMappinghData
  );
  yield takeLatest(
    CONST.PACKAGE_MAPPING.GETT_PACKAGE_MENU_LIST.INIT,
    getPackageMappinghData
  );
  yield takeLatest(CONST.BI_TICKET.GET_DETAILS_TABLE.INIT, getBiTicketData);
  yield takeLatest(CONST.BI_TICKET.GET_ASSIGNEE_LIST.INIT, getBiTicketData);
  yield takeLatest(CONST.BI_TICKET.GET_PRIORITY_LIST.INIT, getBiTicketData);
  yield takeLatest(CONST.BI_TICKET.GET_STATUS_LIST.INIT, getBiTicketData);
  yield takeLatest(CONST.BI_TICKET.UPDATE_DATA.INIT, TicketUpdateSaga);
  yield takeLatest(CONST.BI_TICKET.SINGLE_TICKET.INIT, GetTicketdetails);
  yield takeLatest(
    CONST.EXPORT_REPORT.GET_DETAILS_TABLE.INIT,
    getExportReportData
  );
  yield takeLatest(
    CONST.EXPORT_REPORT.GET_DETAILS_TABLE.REFRESH,
    getExportReportData
  );
}
