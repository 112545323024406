import * as constant from "./lazyFilterConst";
import { getLocalStorage } from "../../Utils/const";
import { MENU_LIST_VALUE } from "../../Utils/AUTH_CONST";

const initialState = {
  // ML initial state
  ML_SUB_CATEGORY_FILTER: {
    loading: true,
    response: null,
    data: [],
    error: null,
  },
  ML_PUBLISHER_FILTER: {
    loading: true,
    response: null,
    data: [],
    error: null,
  },
  ML_SUB_CHANNEL_FILTER: {
    loading: true,
    response: null,
    data: [],
    error: null,
  },
  // IS initial state
  IS_SUB_CATEGORY_FILTER: {
    loading: true,
    response: null,
    data: [],
    error: null,
  },
  IS_PUBLISHER_FILTER: {
    loading: true,
    response: null,
    data: [],
    error: null,
  },
  IS_SUB_CHANNEL_FILTER: {
    loading: true,
    response: null,
    data: [],
    error: null,
  },
  // BM initial state
  BM_PUBLISHER_FILTER: {
    loading: true,
    response: null,
    data: [],
    error: null,
  },
  BM_SUB_CHANNEL_FILTER: {
    loading: true,
    response: null,
    data: [],
    error: null,
  },
  BM_CHANNEL_FILTER: {
    loading: true,
    response: null,
    data: [],
    error: null,
  },
};
const LazyReducer = (
  state = initialState,
  { type, response, error, end = 100 }
) => {
  switch (type) {
    // IS sub category fiLer -=-==-=-=-=-=-=-=-=--=--=--=-=-==-
    case constant.IS_SUB_CATEGORY_LAZY_FILTER_INIT: {
      const IS_SUB_CATEGORY_FILTER = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, IS_SUB_CATEGORY_FILTER };
    }
    case constant.IS_SUB_CATEGORY_LAZY_FILTER_INIT_SUCCESS: {
      const IS_SUB_CATEGORY_FILTER = {
        loading: false,
        response: response.data,
        data: response.data?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, IS_SUB_CATEGORY_FILTER };
    }
    case constant.IS_SUB_CATEGORY_LAZY_FILTER_INIT_ERROR: {
      const IS_SUB_CATEGORY_FILTER = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, IS_SUB_CATEGORY_FILTER };
    }
    case constant.IS_SUB_CATEGORY_LAZY_FILTER_INIT_NEXT: {
      const IS_SUB_CATEGORY_FILTER = {
        ...state.IS_SUB_CATEGORY_FILTER,
        data: state.IS_SUB_CATEGORY_FILTER.response?.slice(0, end) ?? [],
      };
      return { ...state, IS_SUB_CATEGORY_FILTER };
    }

    // IS Publisher -=-=-=-=-=-=-=-=-=-=-
    case constant.IS_PUBLISHER_LAZY_FILTER_INIT: {
      const IS_PUBLISHER_FILTER = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, IS_PUBLISHER_FILTER };
    }
    case constant.IS_PUBLISHER_LAZY_FILTER_INIT_SUCCESS: {
      const IS_PUBLISHER_FILTER = {
        loading: false,
        response: response.data,
        data: response.data?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, IS_PUBLISHER_FILTER };
    }
    case constant.IS_PUBLISHER_LAZY_FILTER_INIT_ERROR: {
      const IS_PUBLISHER_FILTER = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, IS_PUBLISHER_FILTER };
    }
    case constant.IS_PUBLISHER_LAZY_FILTER_INIT_NEXT: {
      const IS_PUBLISHER_FILTER = {
        ...state.IS_PUBLISHER_FILTER,
        data: state.IS_PUBLISHER_FILTER.response?.slice(0, end) ?? [],
      };
      return { ...state, IS_PUBLISHER_FILTER };
    }

    //IS sub channel -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
    case constant.IS_SUB_CHANNEL_LAZY_FILTER_INIT: {
      const IS_SUB_CHANNEL_FILTER = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, IS_SUB_CHANNEL_FILTER };
    }
    case constant.IS_SUB_CHANNEL_LAZY_FILTER_SUCCESS: {
      const IS_SUB_CHANNEL_FILTER = {
        loading: false,
        response: response.data,
        data: response.data?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, IS_SUB_CHANNEL_FILTER };
    }
    case constant.IS_SUB_CHANNEL_LAZY_FILTER_ERROR: {
      const IS_SUB_CHANNEL_FILTER = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, IS_SUB_CHANNEL_FILTER };
    }
    case constant.IS_SUB_CHANNEL_LAZY_FILTER_NEXT: {
      const IS_SUB_CHANNEL_FILTER = {
        ...state.IS_SUB_CHANNEL_FILTER,
        data: state.IS_SUB_CHANNEL_FILTER.response?.slice(0, end) ?? [],
      };
      return { ...state, IS_SUB_CHANNEL_FILTER };
    }


    // ML sub category fiLer -=-==-=-=-=-=-=-=-=--=--=--=-=-==-
    case constant.ML_SUB_CATEGORY_LAZY_FILTER_INIT: {
      const ML_SUB_CATEGORY_FILTER = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, ML_SUB_CATEGORY_FILTER };
    }
    case constant.ML_SUB_CATEGORY_LAZY_FILTER_INIT_SUCCESS: {
      const ML_SUB_CATEGORY_FILTER = {
        loading: false,
        response: response.data,
        data: response.data?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, ML_SUB_CATEGORY_FILTER };
    }
    case constant.ML_SUB_CATEGORY_LAZY_FILTER_INIT_ERROR: {
      const ML_SUB_CATEGORY_FILTER = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, ML_SUB_CATEGORY_FILTER };
    }
    case constant.ML_SUB_CATEGORY_LAZY_FILTER_INIT_NEXT: {
      const ML_SUB_CATEGORY_FILTER = {
        ...state.ML_SUB_CATEGORY_FILTER,
        data: state.ML_SUB_CATEGORY_FILTER.response?.slice(0, end) ?? [],
      };
      return { ...state, ML_SUB_CATEGORY_FILTER };
    }

    // ML Publisher -=-=-=-=-=-=-=-=-=-=-
    case constant.ML_PUBLISHER_LAZY_FILTER_INIT: {
      const ML_PUBLISHER_FILTER = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, ML_PUBLISHER_FILTER };
    }
    case constant.ML_PUBLISHER_LAZY_FILTER_INIT_SUCCESS: {
      const ML_PUBLISHER_FILTER = {
        loading: false,
        response: response.data,
        data: response.data?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, ML_PUBLISHER_FILTER };
    }
    case constant.ML_PUBLISHER_LAZY_FILTER_INIT_ERROR: {
      const ML_PUBLISHER_FILTER = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, ML_PUBLISHER_FILTER };
    }
    case constant.ML_PUBLISHER_LAZY_FILTER_INIT_NEXT: {
      const ML_PUBLISHER_FILTER = {
        ...state.ML_PUBLISHER_FILTER,
        data: state.ML_PUBLISHER_FILTER.response?.slice(0, end) ?? [],
      };
      return { ...state, ML_PUBLISHER_FILTER };
    }

    //ML sub channel -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
    case constant.ML_SUB_CHANNEL_LAZY_FILTER_INIT: {
      const ML_SUB_CHANNEL_FILTER = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, ML_SUB_CHANNEL_FILTER };
    }
    case constant.ML_SUB_CHANNEL_LAZY_FILTER_SUCCESS: {
      const ML_SUB_CHANNEL_FILTER = {
        loading: false,
        response: response.data,
        data: response.data?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, ML_SUB_CHANNEL_FILTER };
    }
    case constant.ML_SUB_CHANNEL_LAZY_FILTER_ERROR: {
      const ML_SUB_CHANNEL_FILTER = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, ML_SUB_CHANNEL_FILTER };
    }
    case constant.ML_SUB_CHANNEL_LAZY_FILTER_NEXT: {
      const ML_SUB_CHANNEL_FILTER = {
        ...state.ML_SUB_CHANNEL_FILTER,
        data: state.ML_SUB_CHANNEL_FILTER.response?.slice(0, end) ?? [],
      };
      return { ...state, ML_SUB_CHANNEL_FILTER };
    }

    //Brand monitoring publishr -=-=--=-=-=-==-==-==
    case constant.BM_PUBLISHER_LAZY_FILTER_INIT: {
      const BM_PUBLISHER_FILTER = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, BM_PUBLISHER_FILTER };
    }
    case constant.BM_PUBLISHER_LAZY_FILTER_INIT_SUCCESS: {
      const BM_PUBLISHER_FILTER = {
        loading: false,
        response: response.data,
        data: response.data?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, BM_PUBLISHER_FILTER };
    }
    case constant.BM_PUBLISHER_LAZY_FILTER_INIT_ERROR: {
      const BM_PUBLISHER_FILTER = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, BM_PUBLISHER_FILTER };
    }
    case constant.BM_PUBLISHER_LAZY_FILTER_INIT_NEXT: {
      const BM_PUBLISHER_FILTER = {
        ...state.BM_PUBLISHER_FILTER,
        data: state.BM_PUBLISHER_FILTER.response?.slice(0, end) ?? [],
      };
      return { ...state, BM_PUBLISHER_FILTER };
    }
    //Brand monitoring sb channel
    case constant.BM_SUB_CHANNEL_LAZY_FILTER_INIT: {
      const BM_SUB_CHANNEL_FILTER = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, BM_SUB_CHANNEL_FILTER };
    }
    case constant.BM_SUB_CHANNEL_LAZY_FILTER_INIT_SUCCESS: {
      const BM_SUB_CHANNEL_FILTER = {
        loading: false,
        response: response.data,
        data: response.data?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, BM_SUB_CHANNEL_FILTER };
    }
    case constant.BM_SUB_CHANNEL_LAZY_FILTER_INIT_ERROR: {
      const BM_SUB_CHANNEL_FILTER = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, BM_SUB_CHANNEL_FILTER };
    }
    case constant.BM_SUB_CHANNEL_LAZY_FILTER_INIT_NEXT: {
      const BM_SUB_CHANNEL_FILTER = {
        ...state.BM_SUB_CHANNEL_FILTER,
        data: state.BM_SUB_CHANNEL_FILTER.response?.slice(0, end) ?? [],
      };
      return { ...state, BM_SUB_CHANNEL_FILTER };
    }
    //Brand monitoring channel
    case constant.BM_CHANNEL_LAZY_FILTER_INIT: {
      const BM_CHANNEL_FILTER = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, BM_CHANNEL_FILTER };
    }
    case constant.BM_CHANNEL_LAZY_FILTER_INIT_SUCCESS: {
      const BM_CHANNEL_FILTER = {
        loading: false,
        response: response.data,
        data: response.data?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, BM_CHANNEL_FILTER };
    }
    case constant.BM_CHANNEL_LAZY_FILTER_INIT_ERROR: {
      const BM_CHANNEL_FILTER = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, BM_CHANNEL_FILTER };
    }
    case constant.BM_CHANNEL_LAZY_FILTER_INIT_NEXT: {
      const BM_CHANNEL_FILTER = {
        ...state.BM_CHANNEL_FILTER,
        data: state.BM_CHANNEL_FILTER.response?.slice(0, end) ?? [],
      };
      return { ...state, BM_CHANNEL_FILTER };
    }
    // AM Filters:
    case constant.AM_DOMAIN_FILTER_INIT: {
      const AM_DOMAIN_FILTER = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, AM_DOMAIN_FILTER };
    }
    case constant.AM_DOMAIN_FILTER_INIT_SUCCESS: {
      const AM_DOMAIN_FILTER = {
        loading: false,
        response: response.data,
        data: response.data?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, AM_DOMAIN_FILTER };
    }
    case constant.AM_DOMAIN_FILTER_INIT_ERROR: {
      const AM_DOMAIN_FILTER = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, AM_DOMAIN_FILTER };
    }
    case constant.AM_DOMAIN_FILTER_INIT_NEXT: {
      const AM_DOMAIN_FILTER = {
        ...state.AM_DOMAIN_FILTER,
        data: state.AM_DOMAIN_FILTER.response?.slice(0, end) ?? [],
      };
      return { ...state, AM_DOMAIN_FILTER };
    }

    case constant.AM_KEYWORD_FILTER_INIT: {
      const AM_KEYWORD_FILTER = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, AM_KEYWORD_FILTER };
    }
    case constant.AM_KEYWORD_FILTER_INIT_SUCCESS: {
      const AM_KEYWORD_FILTER = {
        loading: false,
        response: response.data,
        data: response.data?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, AM_KEYWORD_FILTER };
    }
    case constant.AM_KEYWORD_FILTER_INIT_ERROR: {
      const AM_KEYWORD_FILTER = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, AM_KEYWORD_FILTER };
    }
    case constant.AM_KEYWORD_FILTER_INIT_NEXT: {
      const AM_KEYWORD_FILTER = {
        ...state.AM_KEYWORD_FILTER,
        data: state.AM_KEYWORD_FILTER.response?.slice(0, end) ?? [],
      };
      return { ...state, AM_KEYWORD_FILTER };
    }

    case constant.AM_LOCATION_FILTER_INIT: {
      const AM_LOCATION_FILTER = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, AM_LOCATION_FILTER };
    }
    case constant.AM_LOCATION_FILTER_INIT_SUCCESS: {
      const AM_LOCATION_FILTER = {
        loading: false,
        response: response.data,
        data: response.data?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, AM_LOCATION_FILTER };
    }
    case constant.AM_LOCATION_FILTER_INIT_ERROR: {
      const AM_LOCATION_FILTER = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, AM_LOCATION_FILTER };
    }
    case constant.AM_LOCATION_FILTER_INIT_NEXT: {
      const AM_LOCATION_FILTER = {
        ...state.AM_LOCATION_FILTER,
        data: state.AM_LOCATION_FILTER.response?.slice(0, end) ?? [],
      };
      return { ...state, AM_LOCATION_FILTER };
    }

    case constant.AM_PUBLISHER_FILTER_INIT: {
      const AM_PUBLISHER_FILTER = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, AM_PUBLISHER_FILTER };
    }
    case constant.AM_PUBLISHER_FILTER_INIT_SUCCESS: {
      const AM_PUBLISHER_FILTER = {
        loading: false,
        response: response.data,
        data: response.data?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, AM_PUBLISHER_FILTER };
    }
    case constant.AM_PUBLISHER_FILTER_INIT_ERROR: {
      const AM_PUBLISHER_FILTER = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, AM_PUBLISHER_FILTER };
    }
    case constant.AM_PUBLISHER_FILTER_INIT_NEXT: {
      const AM_PUBLISHER_FILTER = {
        ...state.AM_PUBLISHER_FILTER,
        data: state.AM_PUBLISHER_FILTER.response?.slice(0, end) ?? [],
      };
      return { ...state, AM_PUBLISHER_FILTER };
    }

    case constant.AM_CHANNEL_FILTER_INIT: {
      const AM_CHANNEL_FILTER = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, AM_CHANNEL_FILTER };
    }
    case constant.AM_CHANNEL_FILTER_INIT_SUCCESS: {
      const AM_CHANNEL_FILTER = {
        loading: false,
        response: response.data,
        data: response.data?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, AM_CHANNEL_FILTER };
    }
    case constant.AM_CHANNEL_FILTER_INIT_ERROR: {
      const AM_CHANNEL_FILTER = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, AM_CHANNEL_FILTER };
    }
    case constant.AM_CHANNEL_FILTER_INIT_NEXT: {
      const AM_CHANNEL_FILTER = {
        ...state.AM_CHANNEL_FILTER,
        data: state.AM_CHANNEL_FILTER.response?.slice(0, end) ?? [],
      };
      return { ...state, AM_CHANNEL_FILTER };
    }

    case constant.AM_SUB_CHANNEL_FILTER_INIT: {
      const AM_SUB_CHANNEL_FILTER = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, AM_SUB_CHANNEL_FILTER };
    }
    case constant.AM_SUB_CHANNEL_FILTER_INIT_SUCCESS: {
      const AM_SUB_CHANNEL_FILTER = {
        loading: false,
        response: response.data,
        data: response.data?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, AM_SUB_CHANNEL_FILTER };
    }
    case constant.AM_SUB_CHANNEL_FILTER_INIT_ERROR: {
      const AM_SUB_CHANNEL_FILTER = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, AM_SUB_CHANNEL_FILTER };
    }
    case constant.AM_SUB_CHANNEL_FILTER_INIT_NEXT: {
      const AM_SUB_CHANNEL_FILTER = {
        ...state.AM_SUB_CHANNEL_FILTER,
        data: state.AM_SUB_CHANNEL_FILTER.response?.slice(0, end) ?? [],
      };
      return { ...state, AM_SUB_CHANNEL_FILTER };
    }

    default:
      return state;
  }
};
export default LazyReducer;
