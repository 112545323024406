
import { takeLatest, put } from "redux-saga/effects";
import * as CONST from "./lazyFilterConst";
import { API, APIDROPDOWN } from "../setupAxios";
import { ApiUrl } from "../helpers/Endpoints";

const signout = () => {
  
  localStorage.removeItem("auth_data");
  localStorage.removeItem("dpackage");
  localStorage.removeItem("startDateValue");
  localStorage.removeItem("startDate");
  localStorage.removeItem("endDate");
  window.location = "/";

};
const Endpoint = {
  Filters: {
    INVESTMENT_SCAN_SUB_CATEGORY_FILTER:
      "/investment-scams/filter/sub_category",
    INVESTMENT_SCAN_PUBLISHER_FILTER:
      "/investment-scams/filter/publisher",
    INVESTMENT_SCAN_SUB_CHANNEL_FILTER:
      "/investment-scams/filter/sub_channel_name",
    MERCHANT_LAUNDERING_SUB_CATEGORY_FILTER:
      "/merchant-laundering/filter/sub_category",
    MERCHANT_LAUNDERING_PUBLISHER_FILTER:
      "/merchant-laundering/filter/publisher",
    MERCHANT_LAUNDERING_SUB_CHANNEL_FILTER:
      "/merchant-laundering/filter/sub_channel_name",
    BRAND_MONITORING_PUBLISHER_FILTER:
      "/brand-monitoring/filter/contact_number",
    BRAND_MONITORING_SUB_CHANNEL_FILTER: "/brand-monitoring/filter/sub_channel",
    BRAND_MONITORING_CHANNEL_FILTER: "/brand-monitoring/filter/channel",
    AM_DOMAIN_FILTER:"/affiliate-monitoring/filter/domain",
    AM_LOCATION_FILTER: '/affiliate-monitoring/filter/location',
    AM_KEYWORD_FILTER: '/affiliate-monitoring/filter/keyword',
    AM_PUBLISHER_FILTER:
      '/affiliate-monitoring/filter/publisher',
    AM_CHANNEL_FILTER: '/affiliate-monitoring/filter/channel',
    AM_SUB_CHANNEL_FILTER:
      '/affiliate-monitoring/filter/sub_channel',
  },
};

// IS saga's
export function* IS_SUB_CATEGORY({ payload }) {
  try {
    let url = ApiUrl + Endpoint.Filters.INVESTMENT_SCAN_SUB_CATEGORY_FILTER;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.IS_SUB_CATEGORY_LAZY_FILTER_INIT_SUCCESS,
      response,
    });
  } catch (error) {
const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout()
    yield put({ type: CONST.IS_SUB_CATEGORY_LAZY_FILTER_INIT_ERROR, error });
  }
}
export function* IS_PUBLISHER({ payload }) {
  try {
    let url = ApiUrl + Endpoint.Filters.INVESTMENT_SCAN_PUBLISHER_FILTER;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: CONST.IS_PUBLISHER_LAZY_FILTER_INIT_SUCCESS, response });
  } catch (error) {
const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout()
    yield put({ type: CONST.IS_PUBLISHER_LAZY_FILTER_INIT_ERROR, error });
  }
}
export function* IS_SUB_CHANNEL({ payload }) {
  try {
    let url = ApiUrl + Endpoint.Filters.INVESTMENT_SCAN_SUB_CHANNEL_FILTER;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: CONST.IS_SUB_CHANNEL_LAZY_FILTER_SUCCESS, response });
  } catch (error) {
const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout()
    yield put({ type: CONST.IS_SUB_CHANNEL_LAZY_FILTER_ERROR, error });
  }
}


// ML saga's
export function* ML_SUB_CATEGORY({ payload }) {
  try {
    let url = ApiUrl + Endpoint.Filters.MERCHANT_LAUNDERING_SUB_CATEGORY_FILTER;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.ML_SUB_CATEGORY_LAZY_FILTER_INIT_SUCCESS,
      response,
    });
  } catch (error) {
const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout()
    yield put({ type: CONST.ML_SUB_CATEGORY_LAZY_FILTER_INIT_ERROR, error });
  }
}
export function* ML_PUBLISHER({ payload }) {
  try {
    let url = ApiUrl + Endpoint.Filters.MERCHANT_LAUNDERING_PUBLISHER_FILTER;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: CONST.ML_PUBLISHER_LAZY_FILTER_INIT_SUCCESS, response });
  } catch (error) {
const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout()
    yield put({ type: CONST.ML_PUBLISHER_LAZY_FILTER_INIT_ERROR, error });
  }
}
export function* ML_SUB_CHANNEL({ payload }) {
  try {
    let url = ApiUrl + Endpoint.Filters.MERCHANT_LAUNDERING_SUB_CHANNEL_FILTER;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: CONST.ML_SUB_CHANNEL_LAZY_FILTER_SUCCESS, response });
  } catch (error) {
const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout()
    yield put({ type: CONST.ML_SUB_CHANNEL_LAZY_FILTER_ERROR, error });
  }
}
// BM saga's

export function* BM_PUBLISHER({ payload }) {
  try {
    let url = ApiUrl + Endpoint.Filters.BRAND_MONITORING_PUBLISHER_FILTER;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: CONST.BM_PUBLISHER_LAZY_FILTER_INIT_SUCCESS, response });
  } catch (error) {
const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout()
    yield put({ type: CONST.BM_PUBLISHER_LAZY_FILTER_INIT_ERROR, error });
  }
}
export function* BM_SUB_CHANNEL({ payload }) {
  try {
    let url = ApiUrl + Endpoint.Filters.BRAND_MONITORING_SUB_CHANNEL_FILTER;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.BM_SUB_CHANNEL_LAZY_FILTER_INIT_SUCCESS,
      response,
    });
  } catch (error) {
const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout()
    yield put({ type: CONST.BM_SUB_CHANNEL_LAZY_FILTER_INIT_ERROR, error });
  }
}
export function* BM_CHANNEL({ payload }) {
  try {
    let url = ApiUrl + Endpoint.Filters.BRAND_MONITORING_CHANNEL_FILTER;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.BM_CHANNEL_LAZY_FILTER_INIT_SUCCESS,
      response,
    });
  } catch (error) {
const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout()
    yield put({ type: CONST.BM_CHANNEL_LAZY_FILTER_INIT_ERROR, error });
  }
}

export function* AM_DOMAIN({ payload }) {
  try {
    let url = ApiUrl + Endpoint.Filters.AM_DOMAIN_FILTER;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: CONST.AM_DOMAIN_FILTER_INIT_SUCCESS, response });
  } catch (error) {
const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout()
    yield put({ type: CONST.AM_DOMAIN_FILTER_INIT_ERROR, error });
  }
}
export function* AM_KEYWORD({ payload }) {
  try {
    let url = ApiUrl + Endpoint.Filters.AM_KEYWORD_FILTER;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: CONST.AM_KEYWORD_FILTER_INIT_SUCCESS, response });
  } catch (error) {
const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout()
    yield put({ type: CONST.AM_KEYWORD_FILTER_INIT_ERROR, error });
  }
}
export function* AM_LOCATION({ payload }) {
  try {
    let url = ApiUrl + Endpoint.Filters.AM_LOCATION_FILTER;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: CONST.AM_LOCATION_FILTER_INIT_SUCCESS, response });
  } catch (error) {
const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout()
    yield put({ type: CONST.AM_LOCATION_FILTER_INIT_ERROR, error });
  }
}

export function* AM_PUBLISHER({ payload }) {
  try {
    let url = ApiUrl + Endpoint.Filters.AM_PUBLISHER_FILTER;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: CONST.AM_PUBLISHER_FILTER_INIT_SUCCESS, response });
  } catch (error) {
const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout()
    yield put({ type: CONST.AM_PUBLISHER_FILTER_INIT_ERROR, error });
  }
}
export function* AM_CHANNEL({ payload }) {
  try {
    let url = ApiUrl + Endpoint.Filters.AM_CHANNEL_FILTER;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: CONST.AM_CHANNEL_FILTER_INIT_SUCCESS, response });
  } catch (error) {
const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout()
    yield put({ type: CONST.AM_CHANNEL_FILTER_INIT_ERROR, error });
  }
}
export function* AM_SUB_CHANNEL({ payload }) {
  try {
    let url = ApiUrl + Endpoint.Filters.AM_SUB_CHANNEL_FILTER;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: CONST.AM_SUB_CHANNEL_FILTER_INIT_SUCCESS, response });
  } catch (error) {
const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout()
    yield put({ type: CONST.AM_SUB_CHANNEL_FILTER_INIT_ERROR, error });
  }
}


export default function* IdentityScanSaga() {
  yield takeLatest(CONST.IS_SUB_CATEGORY_LAZY_FILTER_INIT, IS_SUB_CATEGORY);
  yield takeLatest(CONST.IS_PUBLISHER_LAZY_FILTER_INIT, IS_PUBLISHER);
  yield takeLatest(CONST.IS_SUB_CHANNEL_LAZY_FILTER_INIT, IS_SUB_CHANNEL);
  yield takeLatest(CONST.ML_SUB_CATEGORY_LAZY_FILTER_INIT, ML_SUB_CATEGORY);
  yield takeLatest(CONST.ML_PUBLISHER_LAZY_FILTER_INIT, ML_PUBLISHER);
  yield takeLatest(CONST.ML_SUB_CHANNEL_LAZY_FILTER_INIT, ML_SUB_CHANNEL);
  yield takeLatest(CONST.BM_PUBLISHER_LAZY_FILTER_INIT, BM_PUBLISHER);
  yield takeLatest(CONST.BM_SUB_CHANNEL_LAZY_FILTER_INIT, BM_SUB_CHANNEL);
  yield takeLatest(CONST.BM_CHANNEL_LAZY_FILTER_INIT, BM_CHANNEL);
  yield takeLatest(CONST.AM_DOMAIN_FILTER_INIT, AM_DOMAIN);
  yield takeLatest(CONST.AM_KEYWORD_FILTER_INIT, AM_KEYWORD);
  yield takeLatest(CONST.AM_LOCATION_FILTER_INIT, AM_LOCATION);
  yield takeLatest(CONST.AM_PUBLISHER_FILTER_INIT, AM_PUBLISHER);
  yield takeLatest(CONST.AM_CHANNEL_FILTER_INIT, AM_CHANNEL);
  yield takeLatest(CONST.AM_SUB_CHANNEL_FILTER_INIT, AM_SUB_CHANNEL);
}
