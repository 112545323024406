import React, { useMemo } from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import Logo from '../asset/mFilterIt_Logo _Negative.png'

export function Brand() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Brand */}
      <div
        className={`brand flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand"
        style={{backgroundColor:"#530093"}}
      >
        {/* begin::Logo */}
        <div className="brand-logo">
          <img alt="logo" src={layoutProps.headerLogo} height="20" />
        </div>
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button
              className="brand-toggle btn btn-sm px-0"
              id="kt_aside_toggle"
            >
              <span className="svg-icon svg-icon-xl">
                {/* <SVG
                  title="Toggle"
                  src={toAbsoluteUrl(
                    "https://infringementportalcontent.mfilterit.com/images/media/svg/icons/General/Angle-double-left.svg"
                  )}
                /> */}
               {/* <FontAwesomeIcon icon="fa-duotone fa-angles-left" /> */}
               <i className="fa-solid fa-angles-left" style={{color:"#A21094"}}></i>
              </span>
            </button>
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  );
}
