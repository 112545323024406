//TESTING DNS

export const DNSLIST = {
  IDSCAN: {
    SCHEDULE: process.env.REACT_APP_IDSCAN_SCHEDULE,
    DELETE: process.env.REACT_APP_IDSCAN_DELETE,
    NODES: process.env.REACT_APP_IDSCAN_NODES,
    BATCHID: process.env.REACT_APP_IDSCAN_BATCHID,
    BULKDOWNLOADPDF: process.env.REACT_APP_IDSCAN_BULKDOWNLOADPDF,
    PDFBULKJOB: process.env.REACT_APP_IDSCAN_PDFBULKJOB,
    JOBS: process.env.REACT_APP_IDSCAN_JOBS,
    STATUS: process.env.REACT_APP_IDSCAN_STATUS,
    NEWSTATUS: process.env.REACT_APP_IDSCAN_STATUS_NEW,
    ADDRESS_CHECK: process.env.REACT_APP_IDSCAN_ADDRESS_CHECK,
    ADDRESS_CHECK_NEW: process.env.REACT_APP_IDSCAN_ADDRESS_CHECK_NEW,
    GOOGLESEARCH: process.env.REACT_APP_IDSCAN_GOOGLESEARCH,
    REVERSEIMAGE: process.env.REACT_APP_IDSCAN_REVERSEIMAGE,
    LINKEDINSEARCH: process.env.REACT_APP_IDSCAN_LINKEDINSEARCH,
    PDF: process.env.REACT_APP_IDSCAN_PDF,
    DEFAULT: process.env.REACT_APP_IDSCAN_DEFAULT,
    DARKWEB: process.env.REACT_APP_IDSCAN_DARKWEB,
    AVAILBLESOURCES: process.env.REACT_APP_IDSCAN_AVAILBLESOURCES,
    PDFGENERATER: process.env.REACT_APP_IDSCAN_PDFGENERATER,
    NEWPDFGENERATOR: process.env.REACT_APP_IDSCAN_NEWPDFGENERATOR,
    EXPORT: process.env.REACT_APP_IDSCAN_EXPORT,
    BULK_UPLOAD: process.env.REACT_APP_IDSCAN_BULK_UPLOAD,
    EXCELGENERATOR: process.env.REACT_APP_IDSCAN_EXCELGENERATOR,
    FEEDPOST: process.env.REACT_APP_IDSCAN_FEEDPOST,
    USERDETAILS: process.env.REACT_APP_IDSCAN_USERDETAILS,
    APIDETAILS: process.env.REACT_APP_IDSCAN_APIDETAILS,
    SOCIALMEDIAFOLLOWERS: process.env.REACT_APP_IDSCAN_SOCIALMEDIAFOLLOWERS,
    SOCIAMEDIAHIGHLIGHTS: process.env.REACT_APP_IDSCAN_SOCIALMEDIAHIGHLIGHTS,
    SOCIALMEDIAFOLLOWINGS: process.env.REACT_APP_IDSCAN_SOCIALMEDIAFOLLOWINGS,
    SOCIALMEDIAINSTAGRAM: process.env.REACT_APP_IDSCAN_SOCIALMEDIAINSTAGRAM,
    SOCIALMEDIASIMILARPROFILES: process.env.REACT_APP_IDSCAN_SOCIALMEDIASIMILARPROFILES,
    SOCIALMEDIALINKEDINPOST: process.env.REACT_APP_IDSCAN_SOCIALMEDIALINKEDINPOST,
    SOCIALMEDIATAGGEDPOSTS: process.env.REACT_APP_IDSCAN_SOCIALMEDIATAGGEDPOSTS,
    SOCIALMEDIASTORIES: process.env.REACT_APP_IDSCAN_SOCIALMEDIASTORIES,
    SOCIALMEDIAPUBLICSTORIES:
      process.env.REACT_APP_IDSCAN_SOCIALMEDIAPUBLICSTORIES,
    FACEBOOK_FRIENDS: process.env.REACT_APP_IDSCAN_FACEBOOK_FRIENDS,
    SOCIALMEDIASPOTLIGHTS: process.env.REACT_APP_IDSCAN_SOCIALMEDIASPOTLIGHTS,
    REVERSEFACECHECK: process.env.REACT_APP_IDSCAN_REVERSEFACECHECK,
    REVERSETINEYES: process.env.REACT_APP_IDSCAN_REVERSETINEYES,
    REVERSEPIMEYES: process.env.REACT_APP_IDSCAN_REVERSEPIMEYES,
    REVERSEYANDEX: process.env.REACT_APP_IDSCAN_REVERSEYANDEX
  },
  DASHBOARD_360: { HOME: process.env.REACT_APP_DASHBOARD_360_HOME },
  BI: {
    FLOWLIST: process.env.REACT_APP_BI_FLOWLIST,
    INITIALPROCESSIONGDATA: process.env.REACT_APP_BI_INITIALPROCESSIONGDATA,
    SCRAPPEDDATAFORFLOWLIST: process.env.REACT_APP_BI_SCRAPPEDDATAFORFLOWLIST
  }
};

//PRODUCTION DNS

// export const DNSLIST = {
//   IDSCAN: {
//     SCHEDULE: 'https://identityscanschedule.mfilterit.net/',
//     DELETE: 'https://identityscandemgmt.mfilterit.net/delete/',
//     NODES: 'https://identityscan.mfilterit.net/nodes',
//     BATCHID: 'https://identityscan.mfilterit.net/batchID',
//     PDFBULKJOB: 'https://identityscan.mfilterit.net/PDFbulkJob',
//     BULKDOWNLOADPDF: 'https://identityscan.mfilterit.net/bulkPDF',
//     JOBS: 'https://identityscan.mfilterit.net/jobs',
//     STATUS: 'https://identityscan.mfilterit.net/status',
//     ADDRESS_CHECK: 'https://identityscandemo.mfilterit.net/address',
//     GOOGLESEARCH: 'https://identityscan.mfilterit.net/googleSearchResults',
//     REVERSEIMAGE: 'https://identityscan.mfilterit.net/reverseImage',
//     LINKEDINSEARCH: 'https://identityscan.mfilterit.net/linkedInSearchResults',
//     PDF: 'https://identityscanreport.mfilterit.net/report',
//     DEFAULT: 'https://identityscan.mfilterit.net',
//     DARKWEB: 'https://identityscan.mfilterit.net/darkweb',
//     AVAILBLESOURCES: 'https://identityscan.mfilterit.net/availablesources',
//     PDFGENERATER: 'https://identityscan.mfilterit.net/pdfStatus',
//     EXPORT: 'https://identityscan-report.mfilterit.net/report',
//     BULK_UPLOAD: 'https://identityscan.mfilterit.net/bulkJobs',
//     EXCELGENERATOR: 'https://identityscan.mfilterit.net/excelStatus',
//     FEEDPOST: 'https://identityscan.mfilterit.net/feeds',
//     USERDETAILS: 'https://identityscan.mfilterit.net/user_details',
//     APIDETAILS: 'https://identityscan.mfilterit.net/api_details',
//     SOCIALMEDIAFOLLOWERS: 'https://identityscan.mfilterit.net/follower',
//     SOCIALMEDIAFOLLOWINGS: 'https://identityscan.mfilterit.net/following',
//     SOCIALMEDIATAGGEDPOSTS: 'https://identityscan.mfilterit.net/taggedpost',
//     SOCIALMEDIASTORIES: 'https://identityscan.mfilterit.net/stories',
//     SOCIALMEDIAHIGHLIGHTS: 'https://identityscan.mfilterit.net/highlights',
//     SOCIALMEDIAPUBLICSTORIES:
//       'https://identityscan.mfilterit.net/publicstories',
//     SOCIALMEDIASPOTLIGHTS: 'https://identityscan.mfilterit.net/spotlights',
//     FACEBOOK_FRIENDS: 'https://identityscan.mfilterit.net/friends',
//     REVERSEFACECHECK:
//       'https://ohzre3a7uebpqxfbtzqb4eakae0rsxev.lambda-url.us-west-2.on.aws/',
//     REVERSETINEYES:
//       'https://ccejrcuwwm6kspympqlyyjf4lq0lfzvs.lambda-url.us-west-2.on.aws/',
//     REVERSEPIMEYES:
//       'https://wuhjgoft2v6jmptn4nowkgcwji0jcrbv.lambda-url.us-west-2.on.aws/',
//     REVERSEYANDEX:
//       'https://l5ytsfvq5ltalawzgqyimngnkm0woqsv.lambda-url.us-west-2.on.aws/'
//   },
// DASHBOARD_360: {
//   HOME: 'https://360-api.mfilterit.net'
// },
//   BI: {
//     FLOWLIST: 'https://proxyapi-v3.mfilterit.net/api.php?action=get_flowlist',
//     INITIALPROCESSIONGDATA:
//       'https://gaonrigi2ynml4nzn6isuydzkq0jhrjf.lambda-url.us-west-2.on.aws',
//     SCRAPPEDDATAFORFLOWLIST:
//       'https://proxyapi-v3.mfilterit.net/api.php?action=get_scraped_data_api'
//   }
// };
