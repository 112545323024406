/* eslint-disable no-unreachable */
import { EndPoints, ApiUrl, PythonApiUrl } from '../helpers/Endpoints';
import { TOKEN } from '../../Utils/AUTH_CONST';
const CommonServices = {};

const token = localStorage.getItem('token');
const headers = {
  // Accept: '*/*',
  authorization: token,
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

CommonServices.ViewPackagename = function() {
  let url = ApiUrl + EndPoints.package_name;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = {
        data: await response.json(),
        status: response.status,
      };

      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewMenulist = function(packageName) {
  let url = ApiUrl + EndPoints.menu_list + '?package_name=' + packageName;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewFilterlist = function(packageName) {
  let url = ApiUrl + EndPoints.filter_list + '?package_name=' + packageName;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
CommonServices.ViewChannellist = function(packageName, fromDate, toDate) {
  let url =
    ApiUrl +
    EndPoints.channel_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
CommonServices.ViewCountrylist = function(packageName, fromDate, toDate) {
  let url =
    ApiUrl +
    EndPoints.country_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
CommonServices.ViewCategorylist = function(packageName, fromDate, toDate) {
  let url =
    ApiUrl +
    EndPoints.category_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
CommonServices.ViewBrandlist = function(packageName, fromDate, toDate) {
  let url =
    ApiUrl +
    EndPoints.brand_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
CommonServices.ViewPrioritylist = function(packageName, fromDate, toDate) {
  let url =
    ApiUrl +
    EndPoints.priority_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
CommonServices.ViewPublisherlist = function(packageName, fromDate, toDate) {
  let url =
    ApiUrl +
    EndPoints.publisher_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
CommonServices.ViewStatuslist = function(packageName, fromDate, toDate) {
  let url =
    ApiUrl +
    EndPoints.status_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
CommonServices.ViewWebChannellist = function(
  packageName,
  fromDate,
  toDate,
  dashboardtype
) {

  let url =
    ApiUrl +
    EndPoints.web_channel_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate +
    '&dashboard_type=' +
    dashboardtype;
  // let url= 'http://infringementapi.mfilterit.net/api/web-bi/filters/channel?package_name=web.automalluae_v3.cpv&fromDate=2021-01-10&toDate=2022-05-10&dashboard_type=Visit'
  return fetch(url, { mode: 'cors',  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token } })
    .then(async response => {
      const data = await response.json();

      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
CommonServices.ViewWebCampaignlist = function(
  packageName,
  fromDate,
  toDate,
  dashboardtype,
  campageNo,
  campsearch
) {
  let url =
    ApiUrl +
    EndPoints.web_campaign_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate +
    '&dashboard_type=' +
    dashboardtype +
    '&page=' +
    campageNo 
    if(campsearch != ''){
      url = url + '&search=' + campsearch;
    };

  return fetch(url, { mode: 'cors',  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token } })
    .then(async response => {
      const data = await response.json();

      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
CommonServices.ViewCreativelist = function(
  packageName,
  fromDate,
  toDate,
  dashboardtype
) {
  // console.log('packageName,fromDate,toDate', packageName,fromDate,toDate)

  let url =
    ApiUrl +
    EndPoints.web_creative_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate +
    '&dashboard_type=' +
    dashboardtype;

  return fetch(url, { mode: 'cors',  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token } })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
CommonServices.ViewSystemDomainlist = function(
  packageName,
  fromDate,
  toDate,
  dashboardtype,
  pageNo,
  search
) {
  let url =
    ApiUrl +
    EndPoints.web_systemdomain_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate +
    '&dashboard_type=' +
    dashboardtype +
    '&page=' +
    pageNo 
    if(search != ''){
      url = url + '&search=' + search;
    }
 
  return fetch(url, { mode: 'cors',  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token } })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewWebPublisherlist = function(
  packageName,
  fromDate,
  toDate,
  dashboardtype
) {
  let url =
    ApiUrl +
    EndPoints.web_publisher_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate +
    '&dashboard_type=' +
    dashboardtype;


  return fetch(url, { mode: 'cors',  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token } })
    .then(async response => {
      const data = await response.json();


      return data;

      if (!response.ok) {
        const error = (data && data.message) || response.statusText;

        return Promise.reject(error);
      }
    })

    .catch(error => {
      console.error('There was an error!', error);
    });
};
CommonServices.ViewSubPublisherlist = function(
  packageName,
  fromDate,
  toDate,
  dashboardtype
) {
  let url =
    ApiUrl +
    EndPoints.web_sub_publisher_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate +
    '&dashboard_type=' +
    dashboardtype;


  return fetch(url, { mode: 'cors',  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token } })
    .then(async response => {
      const data = await response.json();


      return data;

      if (!response.ok) {
        const error = (data && data.message) || response.statusText;

        return Promise.reject(error);
      }
    })

    .catch(error => {
      console.error('There was an error!', error);
    });
};
CommonServices.ViewPageidlist = function(
  packageName,
  fromDate,
  toDate,
  dashboardtype ,
  pageNo,
  search
) {
  let url =
    ApiUrl +
    EndPoints.web_page_id_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate +
    '&dashboard_type=' +
    dashboardtype
    +
    '&page=' +
    pageNo 
    if(search != ''){
      url = url + '&search=' + search;
    }

  return fetch(url, { mode: 'cors',  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token } })
    .then(async response => {
      const data = await response.json();

      return data;

      if (!response.ok) {
        const error = (data && data.message) || response.statusText;

        return Promise.reject(error);
      }
    })

    .catch(error => {
      console.error('There was an error!', error);
    });
};
CommonServices.ViewPlacementidlist = function(
  packageName,
  fromDate,
  toDate,
  dashboardtype
) {
  let url =
    ApiUrl +
    EndPoints.web_placementid_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate +
    '&dashboard_type=' +
    dashboardtype;
  return fetch(url, { mode: 'cors',  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token } })
    .then(async response => {
      const data = await response.json();
      console.log('priority_list', data);
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
CommonServices.ViewFraudCategorylist = function(
  packageName,
  fromDate,
  toDate,
  dashboardtype
) {
  let url =
    ApiUrl +
    EndPoints.web_fraudcategory_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate +
    '&dashboard_type=' +
    dashboardtype;
  console.log(url, 'fraudurl');
  return fetch(url, { mode: 'cors',  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token } })
    .then(async response => {
      const data = await response.json();
      console.log('publisher_list', data);
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
CommonServices.ViewFraudSubCategorylist = function(
  packageName,
  fromDate,
  toDate,
  dashboardtype
) {
  let url =
    ApiUrl +
    EndPoints.web_fraudsubcategory_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate +
    '&dashboard_type=' +
    dashboardtype;
  console.log(url, 'fraudsuburl');
  return fetch(url, { mode: 'cors',  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token } })
    .then(async response => {
      const data = await response.json();
      console.log('fraudsubcategory_list', data);
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
CommonServices.ViewPublisheridlist = function(
  packageName,
  fromDate,
  toDate,
  dashboardtype
) {
  let url =
    ApiUrl +
    EndPoints.web_publisherid_list +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate +
    '&dashboard_type=' +
    dashboardtype;
  console.log(url, 'publisherurl');
  return fetch(url, { mode: 'cors',  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token } })
    .then(async response => {
      const data = await response.json();

      console.log('publisher_list', data);

      return data;

      if (!response.ok) {
        const error = (data && data.message) || response.statusText;

        return Promise.reject(error);
      }
    })

    .catch(error => {
      console.error('There was an error!', error);
    });
};

// dash board type

CommonServices.ViewDashboard_type_list = function(
  packageName,
  fromDate,
  toDate
) {
  let url =
    ApiUrl + EndPoints.web_dashboard_type + '?package_name=' + packageName;

  // let url = 'http://infringementapi.mfilterit.net/api/user/cl0qx0jl008060imkwqpifmt9/dashboard_type?package_name=web.pidilitev2.cpv'
  return fetch(url, { mode: 'cors',  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token } })
    .then(async response => {
      const data = await response.json();
      console.log('fraudsubcategory_list', data);
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

//click

CommonServices.ViewClickFraudCategorylist = function(
  packageName,
  fromDate,
  toDate,
) {
  let url =
    ApiUrl +
    EndPoints.web_click_fraud_category +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate; 
  return fetch(url, { mode: 'cors',  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token } })
    .then(async response => {
      const data = await response.json();
      // console.log('publisher_list', data);
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewClickFraudSubCategorylist = function(
  packageName,
  fromDate,
  toDate,
) {
  let url =
    ApiUrl +
    EndPoints.web_click_fraud_sub_category +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate; 
  // console.log(url, 'publisherurl');
  return fetch(url, { mode: 'cors',  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token } })
    .then(async response => {
      const data = await response.json();
      // console.log('publisher_list', data);
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewClickPublisherNamelist = function(
  packageName,
  fromDate,
  toDate,
) {
  let url =
    ApiUrl +
    EndPoints.web_click_publisher +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate; 
  // console.log(url, 'publisherurl');
  return fetch(url, { mode: 'cors',  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token } })
    .then(async response => {
      const data = await response.json();
      // console.log('publisher_list', data);
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewClickCampaignIdlist = function(
  packageName,
  fromDate,
  toDate
) {
  let url =
    ApiUrl +
    EndPoints.web_click_campaign +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;
  // console.log(url, 'publisherurl');
  return fetch(url, { mode: 'cors',  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token } })
    .then(async response => {
      const data = await response.json();
      // console.log('publisher_list', data);
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewClickDevicetypelist = function(
  packageName,
  fromDate,
  toDate,
) {
  let url =
    ApiUrl +
    EndPoints.web_click_device +
    '?package_name=' +
    packageName +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;
  // console.log(url, 'publisherurl');
  return fetch(url, { mode: 'cors',  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token } })
    .then(async response => {
      const data = await response.json();
      // console.log('publisher_list', data);
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};


//mtrackit
CommonServices.ViewCampaignlist = function(package_name , fromDate , toDate) {
  let url = ApiUrl + EndPoints.campaign_id + '?package_name=' + package_name + '&fromDate='+ fromDate +'&toDate=' + toDate ;
  
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewEventlist = function(package_name , fromDate , toDate) {
  let url = ApiUrl + EndPoints.event_type+ '?package_name=' + package_name + '&fromDate='+ fromDate +'&toDate=' + toDate ;
  
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewFraudlist = function(package_name , fromDate , toDate) {
  let url = ApiUrl + EndPoints.fraud_type+ '?package_name=' + package_name + '&fromDate='+ fromDate +'&toDate=' + toDate ;
  
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewPublishername = function(package_name, fromDate, toDate) {
  let url =
    ApiUrl +
    EndPoints.publisher_name +
    '?package_name=' +
    package_name +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

//event

CommonServices.ViewEventCampaign = function(package_name, fromDate, toDate, dashboardtype, pageNo, search) {
  let url =
    ApiUrl +
    EndPoints.event_campaign_list +
    '?package_name=' +
    package_name +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate
    +
    '&page=' +
    1 ;
    // if(search != ''){
    //   url = url + '&search=' + search;
    // };
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewEventPage = function(package_name,fromDate,toDate) {
  let url =
    ApiUrl +
    EndPoints.event_page_list +
    '?package_name=' +
    package_name +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate+
    '&page=' +
    1 
    ;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewEventPublisher = function(package_name, fromDate, toDate) {
  let url =
    ApiUrl +
    EndPoints.event_publisher_list +
    '?package_name=' +
    package_name +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate+
    '&page=' +
    1 
    // if(search != ''){
    //   url = url + '&search=' + search;
    // }
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewEventChannel = function(package_name, fromDate, toDate) {
  let url =
    ApiUrl +
    EndPoints.event_channel_list +
    '?package_name=' +
    package_name +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate+
    '&page=' +
    1 
    // if(search != ''){
    //   url = url + '&search=' + search;
    // }
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewEventEvent = function(package_name, fromDate, toDate) {
  let url =
    ApiUrl +
    EndPoints.event_event_list +
    '?package_name=' +
    package_name +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate+
    '&page=' +
    1 ; 
    // if(search != ''){
    //   url = url + '&search=' + search;
    // }
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewWebEventReport = function(package_name , page , limit ) {
  let url =
    ApiUrl +
    EndPoints.web_event_report +
    '?package_name=' +
    package_name 
    // +
    // '&page=' +
    // page +
    // '&limit=' +
    // limit
    ;

  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};


// app event reports

CommonServices.ViewAppEventReport = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.app_event_reports + '?' + searchParams;
  return fetch(url, {
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

// CommonServices.ViewAppEventReport = function(package_name) {
//   let url =
//     ApiUrl +
//     EndPoints.app_event_reports +
//     '?package_name=' +
//     package_name ;

//   return fetch(url, {
//     mode: 'cors',
//     headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
//   })
//     .then(async response => {
//       const data = await response.json();
//       return data;
//       if (!response.ok) {
//         const error = (data && data.message) || response.statusText;
//         return Promise.reject(error);
//       }
//     })
//     .catch(error => {
//       console.error('There was an error!', error);
//     });
// };



// app install reports 

CommonServices.ViewAppInstallReport = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.app_install_reports + '?' + searchParams;
  return fetch(url, {
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};


// CommonServices.ViewAppInstallReport = function(package_name) {
//   let url =
//     ApiUrl +
//     EndPoints.app_install_reports +
//     '?package_name=' +
//     package_name ;

//   return fetch(url, {
//     mode: 'cors',
//     headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
//   })
//     .then(async response => {
//       const data = await response.json();
//       return data;
//       if (!response.ok) {
//         const error = (data && data.message) || response.statusText;
//         return Promise.reject(error);
//       }
//     })
//     .catch(error => {
//       console.error('There was an error!', error);
//     });
// };


// web event report services 
CommonServices.ViewReportFeilds= function(dashboard_type) {
  let url =
    ApiUrl +
    EndPoints.event_report_feilds_list +
    '?dashboard_type=' +
    dashboard_type ;
  
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewReportPublisher= function(package_name,fromDate,toDate) {
  let url =
    ApiUrl +
    EndPoints.event_report_publisher_list +
    '?package_name=' +
    package_name +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate+
    '&page=' +
    1 ;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};



CommonServices.ViewReportFraudCategory= function(package_name,fromDate,toDate) {
  let url =
    ApiUrl +
    EndPoints.event_report_fraud_category_list +
    '?package_name=' +
    package_name +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate+
    '&page=' +
    1 ;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};


CommonServices.ViewReportCampaignlist = function(
  package_name,
  fromDate,
  toDate
) {
  let url =
    ApiUrl +
    EndPoints.report_campaign_id +
    '?package_name=' +
    encodeURIComponent(package_name) +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewReportEventlist = function(package_name, fromDate, toDate) {
  let url =
    ApiUrl +
    EndPoints.report_event_type +
    '?package_name=' +
    encodeURIComponent(package_name) +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;

  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewReportFraudlist = function(package_name, fromDate, toDate) {
  let url =
    ApiUrl +
    EndPoints.report_fraud_type +
    '?package_name=' +
    encodeURIComponent(package_name) +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;

  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewReportPublishername = function(
  package_name,
  fromDate,
  toDate
) {
  let url =
    ApiUrl +
    EndPoints.report_publisher_name +
    '?package_name=' +
    encodeURIComponent(package_name) +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
//mtrackit campaign api

CommonServices.ViewCampaignReportCampaignlist = function(
  package_name,
  fromDate,
  toDate
) {
  let url =
    ApiUrl +
    EndPoints.campaign_report_campaign_id +
    '?package_name=' +
    encodeURIComponent(package_name) +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewCampaignReportPublishername = function(
  package_name,
  fromDate,
  toDate
) {
  let url =
    ApiUrl +
    EndPoints.campaign_report_publisher +
    '?package_name=' +
    encodeURIComponent(package_name) +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewPostbackReportEventlist = function(
  package_name,
  fromDate,
  toDate
) {
  let url =
    ApiUrl +
    EndPoints.postback_report_event +
    '?package_name=' +
    encodeURIComponent(package_name) +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewPostbackReportPbStatuslist = function(
  package_name,
  fromDate,
  toDate
) {
  let url =
    ApiUrl +
    EndPoints.postback_report_pb_status +
    '?package_name=' +
    encodeURIComponent(package_name) +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

CommonServices.ViewPostbackReportPublishername = function(
  package_name,
  fromDate,
  toDate
) {
  let url =
    ApiUrl +
    EndPoints.postback_report_publisher +
    '?package_name=' +
    encodeURIComponent(package_name) +
    '&fromDate=' +
    fromDate +
    '&toDate=' +
    toDate;
  return fetch(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

export default CommonServices;
