import { combineReducers } from "redux";
import ReportReducer from './AffiliateMonitoringRedux/ReportReducer';
import CommonReducer from "./reducers/CommonReducer";
import LoginpagReducer from "./reducers/LoginReducer";
import identityScanReducer from "./AffiliateMonitoringRedux/Reducer";
import MerchantLaunderingReducer from "./MerchantLaundering/Reducers"
import DashboardReducer from "./reducers/DashboardReducer";
import { UserManagementReducer } from "./Common/UserManagement";
import ReportReducerMerchant from "./MerchantLaundering/ReportReducerMerchant";
import InvestmentScam from './InvestmentScams/Reducers';
import BrandMonitoringReducer from "./BrandMonitoringRedux/Reducers";
import ReportReducerBrand from "./MerchantLaundering/ReportReducerMerchant";
import LazyReducer from "./LazyFilters/LazyFilterReducer"
export default function createReducer() {
  const rootReducer = combineReducers({
    dashboard: DashboardReducer,
    common: CommonReducer,
    login: LoginpagReducer,
    UserManagementNew: UserManagementReducer,
    IdentityScan: identityScanReducer,
    report: ReportReducer,
    reportml: ReportReducerMerchant,
    reportbm:ReportReducerBrand,
    MerchantLaundering:MerchantLaunderingReducer,
    BrandMonitoring:BrandMonitoringReducer,
    InvestmentScam:InvestmentScam,
    LazyReducer:LazyReducer
  });
  return rootReducer;
}
