import { fork } from "redux-saga/effects";
import CommonSaga from "./CommonSaga";
import IdentityScanSaga from "../AffiliateMonitoringRedux/Saga";
import LoginSaga from "./LoginSaga";
import { UserManagementSaga } from "../Common/UserManagement";
import Merchantlaunderingsaga from "../MerchantLaundering/Saga";
import InvestmneScamsSaga from '../InvestmentScams/Saga';
import BrandMonitoringsaga from "../BrandMonitoringRedux/Saga"
import LazyFilterSaga from "../LazyFilters/LazyFilterSaga"

export default function* rootSaga(getState) {
  yield fork(CommonSaga);
  yield fork(IdentityScanSaga);
  yield fork(LoginSaga);
  yield fork(UserManagementSaga);
  yield fork(Merchantlaunderingsaga);
  yield fork(BrandMonitoringsaga);
  yield fork(LazyFilterSaga);
  yield fork(InvestmneScamsSaga);
}
