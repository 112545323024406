export default {
  ML_TICKET: {
    GET_DETAILS_TABLE: {
      INIT: "GET_IS_TICKET_TABLE_INIT",
      SUCCESS: "GET_IS_TICKET_TABLE_SUCCESS",
      ERROR: "GET_IS_TICKET_TABLE_ERROR",
      CLEAR: "GET_IS_TICKET_TABLE_CLEAR",
    },
    UPDATE_DATA: {
      INIT: "IS_TICKET_UPDATE_INIT",
      SUCCESS: "GET_IS_TICKET_UPDATE_SUCCESS",
      ERROR: "GET_IS_TICKET_UPDATE_ERROR",
      CLEAR: "GET_IS_TICKET_UPDATE_CLEAR",
    },
    SINGLE_TICKET: {
      INIT: "IS_SINGLE_TICKET_INIT",
      SUCCESS: "IS_GET_SINGLE_TICKET_SUCCESS",
      ERROR: "IS_GET_SINGLE_TICKET_ERROR",
      CLEAR: "IS_GET_SINGLE_TICKET_CLEAR",
    },
    DOWNLOAD_TICKET: {
      INIT: "IS_DOWNLOAD_TICKET_INIT",
      SUCCESS: "IS_DOWNLOAD_TICKET_SUCCESS",
      ERROR: "IS_DOWNLOAD_TICKET_ERROR",
      CLEAR: "IS_DOWNLOAD_TICKET_CLEAR",
    },
    GET_ML_PRIORITY_LIST: {
      INIT: "GET_IS_PRIORITY_LIST_INIT",
      SUCCESS: "GET_IS_PRIORITY_LIST_SUCCESS",
      ERROR: "GET_IS_PRIORITY_LIST_ERROR",
      CLEAR: "GET_IS_PRIORITY_LIST_CLEAR",
    },
    GET_ML_STATUS_LIST: {
      INIT: "GET_IS_STATUS_LIST_INIT",
      SUCCESS: "GET_IS_STATUS_LIST_SUCCESS",
      ERROR: "GET_IS_STATUS_LIST_ERROR",
      CLEAR: "GET_IS_STATUS_LIST_CLEAR",
    },
    GET_ML_ASSIGNEE_LIST: {
      INIT: "GET_IS_ASSIGNEE_LIST_INIT",
      SUCCESS: "GET_IS_ASSIGNEE_LIST_SUCCESS",
      ERROR: "GET_IS_ASSIGNEE_LIST_ERROR",
      CLEAR: "GET_IS_ASSIGNEE_LIST_CLEAR",
    },
  },

  DASHBOARD: {
    TOTAL_COUNTS: {
      INIT: "GET_IS_TOTAL_COUNTS_INIT",
      SUCCESS: "GET_IS_TOTAL_COUNTS_SUCCESS",
      ERROR: "GET_IS_TOTAL_COUNTS_ERROR",
      CLEAR: "GET_IS_TOTAL_COUNTS_CLEAR",
    },
    TOTAL_INCIDENTS: {
      INIT: "GET_IS_TOTAL_INCIDENTS_INIT",
      SUCCESS: "GET_IS_TOTAL_INCIDENTS_SUCCESS",
      ERROR: "GET_IS_TOTAL_INCIDENTS_ERROR",
      CLEAR: "GET_IS_TOTAL_INCIDENTS_CLEAR",
    },
    UNIQUE_UPI_COUNT: {
      INIT: "GET_IS_UNIQUE_UPI_COUNT_INIT",
      SUCCESS: "GET_IS_UNIQUE_UPI_COUNT_SUCCESS",
      ERROR: "GET_IS_UNIQUE_UPI_COUNT_ERROR",
      CLEAR: "GET_IS_UNIQUE_UPI_COUNT_CLEAR",
    },
    UNIQUE_WEBSITE_COUNT: {
      INIT: "GET_IS_UNIQUE_WEBSITE_COUNT_INIT",
      SUCCESS: "GET_IS_UNIQUE_WEBSITE_COUNT_SUCCESS",
      ERROR: "GET_IS_UNIQUE_WEBSITE_COUNT_ERROR",
      CLEAR: "GET_IS_UNIQUE_WEBSITE_COUNT_CLEAR",
    },
    TOP_BANK: {
      INIT: "GET_IS_TOP_BANK_INIT",
      SUCCESS: "GET_IS_TOP_BANK_SUCCESS",
      ERROR: "GET_IS_TOP_BANK_ERROR",
      CLEAR: "GET_IS_TOP_BANK_CLEAR",
    },
    TOP_WEBSITES: {
      INIT: "GET_IS_TOP_WEBSITES_INIT",
      SUCCESS: "GET_IS_TOP_WEBSITES_SUCCESS",
      ERROR: "GET_IS_TOP_WEBSITES_ERROR",
      CLEAR: "GET_IS_TOP_WEBSITES_CLEAR",
    },
    TOP_PAYMENT_GATEWAY: {
      INIT: "GET_IS_TOP_PAYMENT_GATEWAY_INIT",
      SUCCESS: "GET_IS_TOP_PAYMENT_GATEWAY_SUCCESS",
      ERROR: "GET_IS_TOP_PAYMENT_GATEWAY_ERROR",
      CLEAR: "GET_IS_TOP_PAYMENT_GATEWAY_CLEAR",
    },
    TOP_ORIGIN: {
      INIT: "GET_IS_TOP_ORIGIN_INIT",
      SUCCESS: "GET_IS_TOP_ORIGIN_SUCCESS",
      ERROR: "GET_IS_TOP_ORIGIN_ERROR",
      CLEAR: "GET_IS_TOP_ORIGIN_CLEAR",
    },
  },
  DASHBOARD_DOWNLOAD: {
    INCIDENT_VOLUME: {
      INIT: "GET_IS_INCIDENT_VOLUME_INIT",
      SUCCESS: "GET_IS_INCIDENT_VOLUME_SUCCESS",
      ERROR: "GET_IS_INCIDENT_VOLUME_ERROR",
      CLEAR: "GET_IS_INCIDENT_VOLUME_CLEAR",
    },
    TOP10_WEBSITES: {
      INIT: "IS_TOP10_WEBSITES_INIT",
      SUCCESS: "IS_TOP10_WEBSITES_SUCCESS",
      ERROR: "IS_TOP10_WEBSITES_ERROR",
      CLEAR: "IS_TOP10_WEBSITES_CLEAR",
    },
    WEBSITE_HOSTING_COUNTRIES: {
      INIT: "GET_IS_WEBSITE_HOSTING_COUNTRIES_INIT",
      SUCCESS: "GET_IS_WEBSITE_HOSTING_COUNTRIES_SUCCESS",
      ERROR: "GET_IS_WEBSITE_HOSTING_COUNTRIES_ERROR",
      CLEAR: "GET_IS_WEBSITE_HOSTING_COUNTRIES_CLEAR",
    },
    TOP10_BANKS: {
      INIT: "GET_IS_TOP10_BANKS_INIT",
      SUCCESS: "GET_IS_TOP10_BANKS_SUCCESS",
      ERROR: "GET_IS_TOP10_BANKS_ERROR",
      CLEAR: "GET_IS_TOP10_BANKS_CLEAR",
    },
    PAYMENT_GATEWAY: {
      INIT: "GET_IS_PAYMENT_GATEWAY_INIT",
      SUCCESS: "GET_IS_PAYMENT_GATEWAY_SUCCESS",
      ERROR: "GET_IS_PAYMENT_GATEWAY_ERROR",
      CLEAR: "GET_IS_PAYMENT_GATEWAY_CLEAR",
    },
  },

  EXPORT_REPOT: {
    ML_EXPORT_REPORT_TABLE: {
      INIT: "IS_EXPORT_REPORT_TABLE_INIT",
      SUCCESS: "IS_EXPORT_REPORT_TABLE_SUCCESS",
      ERROR: "IS_EXPORT_REPORT_TABLE_ERROR",
      CLEAR: "IS_EXPORT_REPORT_TABLE_CLEAR",
      REFETCH: "IS_EXPORT_REPORT_TABLE_REFETCH",
    },

    ML_GENERATE_REPORT: {
      INIT: "IS_GENERATE_REPORT_INIT",
      SUCCESS: "IS_GENERATE_REPORT_SUCCESS",
      ERROR: "IS_GENERATE_REPORT_ERROR",
      CLEAR: "IS_GENERATE_REPORT_CLEAR",
    },
    ADDITIONAL_FILTER: {
      INIT: "ADDITIONAL_FILTER_INIT",
      SUCCESS: "ADDITIONAL_FILTER_SUCCESS",
      ERROR: "ADDITIONAL_FILTER_ERROR",
      CLEAR: "ADDITIONAL_FILTER_CLEAR",
    },
  },
  AFFILIATE_MONITORING_EXPORT_REPORT: {
    AM_EXPORT_REPORT_TABLE: {
      INIT: "AM_EXPORT_REPORT_TABLE_INIT",
      SUCCESS: "AM_EXPORT_REPORT_TABLE_SUCCESS",
      ERROR: "AM_EXPORT_REPORT_TABLE_ERROR",
      CLEAR: "AM_EXPORT_REPORT_TABLE_CLEAR",
      REFETCH: "AM_EXPORT_REPORT_TABLE_REFETCH",
    },

    AM_GENERATE_REPORT: {
      INIT: "AM_GENERATE_REPORT_INIT",
      SUCCESS: "AM_GENERATE_REPORT_SUCCESS",
      ERROR: "AM_GENERATE_REPORT_ERROR",
      CLEAR: "AM_GENERATE_REPORT_CLEAR",
    },
    ADDITIONAL_FILTER: {
      INIT: "ADDITIONAL_FILTER_INIT",
      SUCCESS: "ADDITIONAL_FILTER_SUCCESS",
      ERROR: "ADDITIONAL_FILTER_ERROR",
      CLEAR: "ADDITIONAL_FILTER_CLEAR",
    },
  },

  INCIDENCES_REPORT: {
    GET_INCIDENCE_REPORT_1: {
      INIT: "GET_INCIDENCE_REPORT_1_INIT",
      SUCCESS: "GET_INCIDENCE_REPORT_1_SUCCESS",
      ERROR: "GET_INCIDENCE_REPORT_1_ERROR",
      CLEAR: "GET_INCIDENCE_REPORT_1_CLEAR",
    },
    INCIDENCES_REPORT: {
      INIT: "IS_INCIDENCES_REPORT_INIT",
      SUCCESS: "IS_INCIDENCES_REPORT_SUCCESS",
      ERROR: "IS_INCIDENCES_REPORT_ERROR",
      CLEAR: "IS_INCIDENCES_REPORT_CLEAR",
    },
    GET_ML_PRIORITY_LIST: {
      INIT: "GET_IS_PRIORITY_LIST_INIT",
      SUCCESS: "GET_IS_PRIORITY_LIST_SUCCESS",
      ERROR: "GET_IS_PRIORITY_LIST_ERROR",
      CLEAR: "GET_IS_PRIORITY_LIST_CLEAR",
    },
    GET_ML_STATUS_LIST: {
      INIT: "GET_IS_STATUS_LIST_INIT",
      SUCCESS: "GET_IS_STATUS_LIST_SUCCESS",
      ERROR: "GET_IS_STATUS_LIST_ERROR",
      CLEAR: "GET_IS_STATUS_LIST_CLEAR",
    },
    GET_ML_ASSIGNEE_LIST: {
      INIT: "GET_IS_ASSIGNEE_LIST_INIT",
      SUCCESS: "GET_IS_ASSIGNEE_LIST_SUCCESS",
      ERROR: "GET_IS_ASSIGNEE_LIST_ERROR",
      CLEAR: "GET_IS_ASSIGNEE_LIST_CLEAR",
    },
  },
  REPORT_CONFIG: {
    GET_REPORT_CONFIG_TABLE: {
      INIT: "IS_GET_REPORT_CONFIG_TABLE_INIT",
      SUCCESS: "IS_GET_REPORT_CONFIG_TABLE_SUCCESS",
      ERROR: "IS_GET_REPORT_CONFIG_TABLE_ERROR",
      CLEAR: "IS_GET_REPORT_CONFIG_TABLE_CLEAR",
    },
    UPDATE_REPORT_CONFIG: {
      INIT: "UPDATE_REPORT_CONFIG_INIT",
      SUCCESS: "UPDATE_REPORT_CONFIG_SUCCESS",
      ERROR: "UPDATE_REPORT_CONFIG_ERROR",
      CLEAR: "UPDATE_REPORT_CONFIG_CLEAR",
    },
    SINGLE_REPORT_CONFIG: {
      INIT: "SINGLE_REPORT_CONFIG_INIT",
      SUCCESS: "SINGLE_REPORT_CONFIG_SUCCESS",
      ERROR: "SINGLE_REPORT_CONFIG_ERROR",
      CLEAR: "SINGLE_REPORT_CONFIG_CLEAR",
    },
    DELETE_REPORT_CONFIG: {
      INIT: "DELETE_REPORT_CONFIG_INIT",
      SUCCESS: "DELETE_REPORT_CONFIG_SUCCESS",
      ERROR: "DELETE_REPORT_CONFIG_ERROR",
      CLEAR: "DELETE_REPORT_CONFIG_CLEAR",
    },
    ADD_REPORT_CONFIG: {
      INIT: "ADD_REPORT_CONFIG_INIT",
      SUCCESS: "ADD_REPORT_CONFIG_SUCCESS",
      ERROR: "ADD_REPORT_CONFIG_ERROR",
      CLEAR: "ADD_REPORT_CONFIG_CLEAR",
    },
  },
};
